import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import INftData from "../../../../interfaces/NftData";
import { useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import { getSelectedNftData } from "../../../select-nft/state";
import { MintState } from "../../SacrificeSection";
import sacrificePopupContent, {
  PopupContentEnum,
  sacrificePopupErrorContent,
} from "../content/components/ctas/contants/sacrificePopupContent";
import txnFns from "./constants/txnFns";
import shouldClosePopups from "./utils/shouldClosePopups";

export type SacrificePopupStateProps = {
  buttonText: string;
  title: string;
};

export const sacrificePopupInitState: SacrificePopupStateProps = {
  title: "",
  buttonText: "",
};

type SacrificePopupProps = {
  mintState: MintState;
  runSacrificeAnimations: () => void;
};

const SacrificePopup: React.FC<SacrificePopupProps> = ({
  mintState,
  runSacrificeAnimations,
}) => {
  const provider = useAppSelector(
    selectWalletInfo("provider")
  ) as ethers.providers.Web3Provider;
  const selectedNft = useAppSelector(getSelectedNftData()) as INftData;
  const [clicked, setClicked] = useState(false);
  const [popupIndex, setPopupIndex] = useState(
    PopupContentEnum.APPROVE_TRANSFER
  );

  const [popupContent, setPopupContent] = useState<SacrificePopupStateProps>({
    ...sacrificePopupContent[popupIndex],
  });

  const onButtonClicked = async () => {
    if (popupIndex === PopupContentEnum.Error) window.location.reload();
    const fn = txnFns.get(popupIndex);
    if (fn === undefined) return;
    setClicked(true);
    const res = await fn(
      provider,
      selectedNft,
      mintState === MintState.SACRIFICE_STAKE_POPUP
    );
    if (res.err) {
      setPopupIndex(PopupContentEnum.Error);
      console.error(res.msg);
      setClicked(false);
      return;
    }
    if (shouldClosePopups(mintState, popupIndex)) {
      runSacrificeAnimations();
      return;
    }
    setPopupIndex(
      mintState === MintState.SACRIFICE_POPUP
        ? PopupContentEnum.SACRIFICE
        : PopupContentEnum.SACRIFICE_STAKE
    );
    setClicked(false);
  };

  useEffect(() => {
    if (popupIndex === PopupContentEnum.Error) {
      setPopupContent({
        ...sacrificePopupErrorContent,
      });
      return;
    }
    setPopupContent({
      ...sacrificePopupContent[popupIndex],
    });
  }, [popupIndex]);

  return (
    <div className="popup-container flex-center">
      <div className="sacrifice-popup-wrapper flex-center column m-pad bg-grad-down round-corners-narrow">
        <EVGenericText
          text={popupContent.title}
          size={"medium"}
          extraClassNames={"l-pad"}
          customCSS={{ marginBottom: "0.25rem" }}
        />
        <EVGenericButton
          text={popupContent.buttonText}
          cb={onButtonClicked}
          disabled={clicked}
          extraClassNames={"l-mb"}
        />
      </div>
    </div>
  );
};

export default SacrificePopup;
