import React, { useEffect, useState } from 'react';
import CountdownTimer from '../../../../components/conutdown-display/CountdownTimer';
import EVGenericButton from '../../../../components/ev-generic-components/EVGenericButton';
import EVGenericText from '../../../../components/ev-generic-components/EVGenericText';
import ForegroundStageId from '../../../../constants/ForegroundStageId';
import { useAppDispatch } from '../../../../state/hooks';
import { disconnectWallet } from '../../../../state/wallet-info-state';
import getCurrentCountdownDisplayIndex from '../../../../utils/getCurrentCountdown';
import { setForegroundStageId } from '../../../main-design-system/state';
import countdownDisplayContent from '../../../sacrifice-countdown/constants/countdownDisplayContent';
import CountdownDisplayIndex from '../../../sacrifice-countdown/constants/CountdownDisplayIndex';
import ICountdownContent from '../../../sacrifice-countdown/interfaces/ICountdownContent';
import { ctaOneText, subtitleText, titleText } from './constants/BlessedDoneTxt';

const buttonsWidth = 70 + '%';

const BlessedDone = () => {
  const [{ title, targetDate }, setCountdownContent] = useState<ICountdownContent>(
    countdownDisplayContent.get(CountdownDisplayIndex.WHITELIST_MINT) as ICountdownContent
  );

  useEffect(
    () => setCountdownContent(countdownDisplayContent.get(getCurrentCountdownDisplayIndex()) as ICountdownContent),
    []
  );

  return (
    <div className='invalid-wallet-errors-no-qualified-nfts-container flex column bg-grad-ltr align-start m-pad round-corners-narrow'>
      <Titles />
      <CountdownTimer targetDate={targetDate} />
      <CTAs />
    </div>
  );
};

const Titles: React.FC = () => (
  <>
    <EVGenericText text={titleText} size={'large'} type={'h2'} weight={'bold'} extraClassNames={'s-mb'} />
    <EVGenericText text={subtitleText} type={'h3'} extraClassNames={'m-mb'} />
  </>
);

const CTAs: React.FC = () => {
  const dispatch = useAppDispatch();

  const onBackButton = () => {
    dispatch(disconnectWallet());
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  return (
    <>
      <EVGenericButton
        text={ctaOneText}
        extraClassNames={'m-mb'}
        customCSS={{
          width: buttonsWidth,
        }}
        cb={onBackButton}
      />
    </>
  );
};

export default BlessedDone;
