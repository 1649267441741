import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import axios, { Axios, AxiosRequestConfig } from "axios"
import INftData from "../../../interfaces/NftData";
import IGetWalletInfoResponse from "../../../state/wallet-info-state/interface/GetWalletInfoResponse";

interface moralisNFTResponseObject {
    token_address: string,
    token_id: string,
    contract_type: string | undefined,
    token_uri: string | undefined,
    metadata: string | undefined,
    name: string
}

interface userNftsObject {
    address: string,
    tokenId: string,
    contractType: string | undefined,
    tokenUri: string | undefined,
    metadata: object | undefined,
    name: string
}

let createMoralisRequestOptions = (
	address: string,
    cursor: string | undefined
) => {
	const options : AxiosRequestConfig = {
	  method: 'GET',
	  url: `https://deep-index.moralis.io/api/v2/${address}/nft`,
	  params: { cursor },
	  headers: {accept: 'application/json', 'X-API-Key': 'test'}
	}
    if (!cursor)
	  delete options?.params;
  return options;
}

let parseMoralisNFTResponse = (response: moralisNFTResponseObject) => {
    return {
        address: response.token_address,
        tokenId: response.token_id,
        contractType: response.contract_type,
        tokenUri: response.token_uri,
        metadata: response.metadata ? JSON.parse(response.metadata) : undefined,
        name: response.name
    }
}

let getAllNFTsOfUser = async (address: string) => {
	let nfts : Array<userNftsObject> = [];
    let cursor;
  
    let firstReqOptions = createMoralisRequestOptions(address, cursor);
    let firstReqResponse = await axios.request(firstReqOptions);
    cursor = firstReqResponse.data.cursor;
    nfts = nfts.concat(firstReqResponse.data.result.map(parseMoralisNFTResponse));
    while (cursor !== undefined && cursor !== null) {
        let reqOptions = createMoralisRequestOptions(address, cursor);
        let reqResponse = await axios.request(reqOptions);
        cursor = reqResponse.data.cursor;
        nfts = nfts.concat(reqResponse.data.result.map(parseMoralisNFTResponse));       
    }

    return nfts;
}


/**
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The NFTs of the user
 */
const getUserAllNfts = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<Array<userNftsObject>>> =>{
    try {
        let nfts = await getAllNFTsOfUser(walletAddress)
        return {err: false, output: nfts};
    } catch (error) {
        return {err: true, msg: `get-user-all-nfts/index.ts::getUserAllNfts():: Failed to get user all Nfts! || \n${error}`};
    }
}

export default getUserAllNfts;