import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import waitForSeconds from "../../../utils/waitForSeconds";

import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getContractInstance from "../utils/getContractInstance";

/**
 * @KASH
 * 
 * @TODO get from smart contract the amount of ev nfts remaining to mint.
 * 
 * @returns number
 */
const getAmountNFTsRemaining = async (provider: ethers.providers.Web3Provider): Promise<OutputI<number>> => {
    try {
        let sacrificeContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        let vikingsLeft = await sacrificeContract.getRemainingVikings()
        const output = vikingsLeft.toNumber();
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `get-amount-nfts-remaining/index.ts::getAmountNFTsRemaining():: Failed to get the amount of ev nfts remaining to mint! || \n${error}`};
    }
}

export default getAmountNFTsRemaining;