import CountdownDisplayIndex from "../features/sacrifice-countdown/constants/CountdownDisplayIndex";

const getCurrentCountdownDisplayIndex = (): CountdownDisplayIndex=> {
    const currentTime = Date.now();
    const preMintTime = parseInt(process.env.REACT_APP_PREMINT_MINT_DATE);
    if( preMintTime > currentTime ){
        return CountdownDisplayIndex.WHITELIST_MINT;
    }
    const publicMintTime = parseInt(process.env.REACT_APP_PUBLIC_MINT_DATE);
    if( publicMintTime > currentTime ){
        return CountdownDisplayIndex.PRE_MINT;
    }
    return CountdownDisplayIndex.PUBLIC_MINT
}

export default getCurrentCountdownDisplayIndex;