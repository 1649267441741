import React, { useState } from "react";
import EVGenericButton from "../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../components/ev-generic-components/EVGenericText";
import { useAppDispatch } from "../../../../../state/hooks";
import openPanelInNewTab from "../../../../../utils/openPanelInNewTab";
import {
  afterBubbleText,
  afterCtaBubbleText,
} from "../../../constants/bribeTxts";
import { setTextBubbleIndex } from "../../../state";
import setGaurdAnimationVariant from "../../../state/actions";
import { GuardVariantNames } from "../../gaurd-entity/animations/variants";
import { TextBubbleIndex } from "../constants/TextBubbleIndex";
import { defaultPosition } from "../constants/textBubblesPositionCSS";
import { BaseTextBubble } from "./BaseTextBubble";

const AfterBribeTextBubble: React.FC = () => {
  const [clicked, setClicked] = useState(false);

  const dispatch = useAppDispatch();

  const onAfterBribeClick = async () => {
    if (clicked) return;
    setClicked(true);
    dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
    await dispatch(setGaurdAnimationVariant(GuardVariantNames.LEAVE));
    openPanelInNewTab();
  };

  return (
    <BaseTextBubble positionCSS={defaultPosition} direction={"left"}>
      <div className="flex-center column">
        <EVGenericText
          text={afterBubbleText}
          extraClassNames={"m-line-h w-all"}
        />
        <EVGenericButton
          text={afterCtaBubbleText}
          cb={onAfterBribeClick}
          extraClassNames={"s-mb s-mt"}
        />
      </div>
    </BaseTextBubble>
  );
};

export default AfterBribeTextBubble;
