import { ethers } from 'ethers';
import React, { useState } from 'react';
import { BribeMintImg } from '../../../../../assets/images';
import { useAppDispatch, useAppSelector } from '../../../../../state/hooks';
import { selectWalletInfo } from '../../../../../state/wallet-info-state';
import mintStakeOneAsyncAction from '../../../../../state/wallet-info-state/actions/mintStakeOneAsyncAction';
import waitForSeconds from '../../../../../utils/waitForSeconds';
import { currentCtaBubbleText } from '../../../constants/bribeTxts';
import { setTextBubbleIndex } from '../../../state';
import BribeMintCard from '../../mint-card/BribeMintCard';
import { TextBubbleIndex } from '../constants/TextBubbleIndex';
import { mintPosition } from '../constants/textBubblesPositionCSS';
import { BaseTextBubble } from './BaseTextBubble';

const CurrentBribeTextBubble: React.FC = () => {
  const [clicked, setClicked] = useState(false);

  const walletInfoProvider = useAppSelector(selectWalletInfo('provider')) as ethers.providers.Web3Provider;
  const userBalance = useAppSelector(selectWalletInfo('userBalance')) as number;

  const dispatch = useAppDispatch();

  const onMintStakeBribeClick = async () => {
    try {
      if (clicked) return;
      setClicked(true);
      await dispatch(mintStakeOneAsyncAction(walletInfoProvider));
      dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
      await waitForSeconds(0.2);
      dispatch(setTextBubbleIndex(TextBubbleIndex.AFTER_MINT));
      setClicked(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <BaseTextBubble positionCSS={mintPosition} direction={'left'}>
      <BribeMintCard
        img={BribeMintImg}
        ethAmount={process.env.REACT_APP_ETH_MINT_AMOUNT || "0"}
        ctaText={currentCtaBubbleText}
        ctaFn={onMintStakeBribeClick}
        disabled={false}
        userBalance={userBalance}
      />
    </BaseTextBubble>
  );
};

export default CurrentBribeTextBubble;
