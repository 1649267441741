import { AnimatePresence, AnimationControls, motion } from "framer-motion";
import React from "react";
import { GuardMoving } from "../../../../assets/images";
import { useAppSelector } from "../../../../state/hooks";
import { selectBribe } from "../../state";
import { GuardVariants } from "./animations/variants";
import "./Guard.scss";

const Guard: React.FC = () => {
  const guardControl = useAppSelector(
    selectBribe("guardControl")
  ) as AnimationControls;

  return (
    <AnimatePresence>
      <motion.div className="guard-container">
        <motion.div className="guard-wrapper">
          <motion.img
            variants={GuardVariants}
            animate={guardControl}
            src={GuardMoving}
          />
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default Guard;
