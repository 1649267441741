import { SacrificePopupStateProps } from "../../../../sacrifice-popup/SacrificePopup";

export enum PopupContentEnum {
    APPROVE_TRANSFER = 0,
    SACRIFICE,
    SACRIFICE_STAKE,
    Error = 99
}

const sacrificePopupContent: SacrificePopupStateProps[] = [
    {
        title: "To approve click here",
        buttonText: "Approve",
    },
    {
        title: "To sacrifice click here",
        buttonText: "Sacrifice",
    },
    {
        title: "To Sacrifice&Stake\nthe NFT click here",
        buttonText: "Sacrifice&Stake NFT",
    },
]

export const sacrificePopupErrorContent = {
        title: "An Error Occurd!",
        buttonText: "Refresh page",
    }

export default sacrificePopupContent;