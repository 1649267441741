export const titleText = 'Eternal Viking';
export const subtitleBeforeMintText = 'Sacrifice your NFT to be blessed with an';
export const subtitleAfterMintText = 'The Gods have blessed you with an';
export const beforeMintCtaOneText = 'Sacrifice&Stake';
export const afterMintCtaOneText = 'Go to Panel';
export const ctaSeparatorText = 'or';
export const beforeMintCtaTwoText = 'Just Sacrifice';
export const afterMintCtaTwoText = 'Sacrifice another NFT';
export const backButtonText = 'Back';
export const helperText = 'Staking enables you to earn $GOLD and sacrifice\nanother NFT (max 2 per wallet)';
