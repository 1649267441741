import axios from "axios";
import ethers from "ethers";
import WalletStatusId from "../../../constants/WalletStatusId";
import OutputI from "../../../interfaces/Output";
import validateWalletAddress from "../../../utils/validateWalletAddress";
import waitForSeconds from "../../../utils/waitForSeconds";
import IGetWalletInfoResponse from "../interface/GetWalletInfoResponse";
import getMockWalletInfoResponse from "../utils/getMockWalletResponse";

import getTestnetWalletResponse from "../utils/getTestnetWalletResponse";

import getMintedEvAmount from "../../../services/ethers/get-minted-ev-amount";
import getStakedEvAmount from "../../../services/ethers/get-staked-ev-amount";
import getPremintMerkleProofs from "../../../services/ethers/utils/getPremintMerkleProof";

import getBlessedTokensOfUser from "../../../services/ethers/get-blessed-tokens-of-user";
import getUserQualifiedNfts from "../../../services/ethers/get-user-qualified-nfts";

import getEthBalance from "../../../services/ethers/get-eth-balance";

/**
 * @KASH
 * @TODO - Please insert the Api base url to .env file missing for getting the required info. make sure to include: prod & dev urls.
 * @param walletAddr 
 * @returns 
 */
const getWalletInfoRequest = async (provider: ethers.providers.Web3Provider, walletAddr: string): Promise<OutputI<IGetWalletInfoResponse>> => {
    try {
        if(!walletAddr || !validateWalletAddress(walletAddr)) throw new Error(`Wallet address is invalid! | Input: ${walletAddr}`);

        let output: IGetWalletInfoResponse;
        const qualifiedNftsOutput = await getUserQualifiedNfts(provider, walletAddr);

        if (qualifiedNftsOutput.err || !qualifiedNftsOutput.output) {
            return {err: true, msg: `connect-wallet/api/index.ts::getWalletInfoRequest():: Did not get wallet info from API`} 
        }
        const qualifiedNfts = qualifiedNftsOutput.output
        const blessedTokens = await getBlessedTokensOfUser(provider, walletAddr);

        let userBalance = await getEthBalance(provider, walletAddr);       
        if (userBalance.err || userBalance.output === undefined) {
            return {err: true, msg: `connect-wallet/api/index.ts::userBalance():: Did not get balance of user`} 
        }

        output = {
            qualifiedNfts: (blessedTokens.output || []).concat(qualifiedNfts.qualifiedNfts),
            walletStatusId: qualifiedNfts.walletStatusId,
            userBalance: userBalance.output
        }
        if (output.qualifiedNfts.length > 0)
            output.walletStatusId = WalletStatusId.MINT

        const wlTokenOutput = output.qualifiedNfts.filter(n => n.swapInfo.address.toLowerCase() === process.env.REACT_APP_WL_TOKEN_ADDR?.toLowerCase());

        let premintTime = process.env.REACT_APP_PREMINT_MINT_DATE || 0;        

        let mintedEvsOutput = await getMintedEvAmount(provider, walletAddr);
        if (mintedEvsOutput.err || mintedEvsOutput.output === undefined) {
            throw new Error();
        }          
        let mintedEvs = mintedEvsOutput.output

        let stakedEvsOutput = await getStakedEvAmount(provider, walletAddr);
        if (stakedEvsOutput.err || stakedEvsOutput.output === undefined) {
            throw new Error();
        }

        let now = Date.now();
        if (now < premintTime) {
            if (wlTokenOutput.length > 0 ) {
                output.walletStatusId = WalletStatusId.MINT;
                output.qualifiedNfts = output.qualifiedNfts.filter(t => t.swapInfo.address.toLowerCase() === process.env.REACT_APP_WL_TOKEN_ADDR?.toLowerCase())         
            } else {
                if (mintedEvs > 0)
                    output.walletStatusId = WalletStatusId.BLESSED_DONE
                else
                    output.walletStatusId = WalletStatusId.NOT_WHITELISTED;
            }
        } else {
            if (mintedEvs >= 5) {
                output.walletStatusId = WalletStatusId.EXCEEDED_AMOUNT
            };
        }

        return {err: false, output}
    } catch (error) {       
        return {err: true, msg: `connect-wallet/api/index.ts::getWalletInfoRequest():: Failed to get wallet info from API | \n${error}`}   
    }
}

export default getWalletInfoRequest;