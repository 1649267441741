import React from "react";
import "./OpenSeaNftValidatorRight.scss";
import { useAppSelector } from "../../state/hooks";
import OpenSeaNftValidatorConnectedRight from "./components/connected/OpenSeaNftValidatorConnectedRight";
import OpenSeaNftValidatorForm from "./components/form/OpenSeaNftValidatorForm";
import OpenSeaNftValidatorResultRight from "./components/result/OpenSeaNftValidatorResultRight";
import { OpenSeaNftValidatorStageId } from "./constants/OpenSeaNftValidatorStageId";
import { selectOpenSeaNftValidator } from "./state";

const OpenSeaNftValidatorRight: React.FC = () => {
  const stageId = useAppSelector(
    selectOpenSeaNftValidator("stageId")
  ) as OpenSeaNftValidatorStageId;

  return (
    <div className="opensea-nft-validator-right-container round-corners-narrow s-pad bg-grad-ltr">
      {stageId === OpenSeaNftValidatorStageId.FORM && (
        <OpenSeaNftValidatorForm />
      )}
      {stageId === OpenSeaNftValidatorStageId.RESULT && (
        <OpenSeaNftValidatorResultRight />
      )}
      {stageId === OpenSeaNftValidatorStageId.CONNECTED && (
        <OpenSeaNftValidatorConnectedRight />
      )}
    </div>
  );
};

export default OpenSeaNftValidatorRight;
