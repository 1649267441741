import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getSignerAddress from "../utils/getSignerAddress";

import getPremintMerkleProofs from "../utils/getPremintMerkleProof";
import getMintedEvAmount from "../get-minted-ev-amount";

/**
 * @KASH
 * 
 * @TODO check if user can bribe to mint one nft and if can bribe to mint and stake two nfts.
 * Do that using the available information about how many nfts does the user own
 * and by checking the amount of eth that the user has.
 * 
 * 1. If doesn't have any EV NFTs
 * 2. If user has **Only One** nft and **Only** if its **currently staked**.
 * 
 * @returns boolean
 */
const checkCanBribe = async (provider: ethers.providers.Web3Provider): Promise<OutputI<boolean>> => {
    try {
        let now = Date.now()
        let wallet = await getSignerAddress(provider);

        if (now <= parseInt(process.env.REACT_APP_PREMINT_MINT_DATE))
            return { err: false, output: false }
                
        // if (now <= parseInt(process.env.REACT_APP_PUBLIC_MINT_DATE)) {           
        //     let preMintProof = getPremintMerkleProofs(wallet);
        //     if (!preMintProof || preMintProof.length === 0)
        //         return { err: false, output: false }
        // }

        let mintedEvAmount = (await getMintedEvAmount(provider, wallet)).output || 0;       
        if (mintedEvAmount >= 5)
            return { err: false, output: false }
        else
            return {err: false, output: true};
    } catch (error) {
        return {err: true, msg: `check-if-user-can-bribe/index.ts::checkCanBribe():: Failed to check if user can bribe to mint! || \n${error}`};
    }
}

export default checkCanBribe;