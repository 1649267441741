import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import BackButton from "../../components/back-button/BackButton";
import ForegroundStageId from "../../constants/ForegroundStageId";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import waitForSeconds from "../../utils/waitForSeconds";
import {
  selectMainLayoutSystem,
  setForegroundStageId,
} from "../main-design-system/state";
import Guard from "./components/gaurd-entity/Gaurd";
import { TextBubbleIndex } from "./components/text-bubble/constants/TextBubbleIndex";
import { selectBribe, setTextBubbleIndex } from "./state";
import "./Bribe.scss";
import { GuardVariantNames } from "./components/gaurd-entity/animations/variants";
import TextBubble from "./components/text-bubble/TextBubble";
import setGaurdAnimationVariant from "./state/actions";

const Bribe: React.FC = () => {
  const dispatch = useAppDispatch();

  const foregroundStageId = useAppSelector(
    selectMainLayoutSystem("foregroundStageId")
  ) as ForegroundStageId;

  const currentTextBubbleIndex = useAppSelector(
    selectBribe("textBubbleIndex")
  ) as TextBubbleIndex;

  const beginBribeSequence = async () => {
    await dispatch(setGaurdAnimationVariant(GuardVariantNames.RESET)).unwrap();
    await dispatch(
      setGaurdAnimationVariant(GuardVariantNames.FLY_TO_MID)
    ).unwrap();
    dispatch(setTextBubbleIndex(TextBubbleIndex.BEFORE_MINT));
    await dispatch(
        setGaurdAnimationVariant(GuardVariantNames.IDLE_HOVER)
      ).unwrap();
    //await dispatch(
    //  setGaurdAnimationVariant(GuardVariantNames.IDLE_HOVER)
    //).unwrap();
  };

  const onBackButtonClick = async () => {
    dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
    await dispatch(setGaurdAnimationVariant(GuardVariantNames.LEAVE)).unwrap();
    dispatch(setForegroundStageId(ForegroundStageId.NULL));
    await waitForSeconds(0.75);
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  useEffect(() => {
    beginBribeSequence();
  }, []);

  const shouldShowBackButton =
    foregroundStageId === ForegroundStageId.BRIBE &&
    (currentTextBubbleIndex === TextBubbleIndex.BEFORE_MINT ||
      currentTextBubbleIndex === TextBubbleIndex.CURRENT_MINT);

  return (
    <div className="bribe-container">
      <TextBubble />
      <Guard />
      <AnimatePresence>
        {shouldShowBackButton && <BackButton onBackClick={onBackButtonClick} />}
      </AnimatePresence>
      <AnimatePresence>
        {foregroundStageId === ForegroundStageId.BRIBE && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 0 } }}
            exit={{ opacity: 0, transition: { duration: 0 } }}
            className="bribe-background"
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default Bribe;
