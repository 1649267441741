import React from "react";
import EVGenericText from "../../../../../components/ev-generic-components/EVGenericText";
import ForegroundStageId from "../../../../../constants/ForegroundStageId";
import { useAppDispatch } from "../../../../../state/hooks";
import { setForegroundStageId } from "../../../../main-design-system/state";
import {
  introTwoBubbleText,
  introCtaBubbleText,
  introOneBubbleText,
} from "../../../constants/bribeTxts";
import { setTextBubbleIndex } from "../../../state";
import setGaurdAnimationVariant from "../../../state/actions";
import { GuardVariantNames } from "../../gaurd-entity/animations/variants";
import { TextBubbleIndex } from "../constants/TextBubbleIndex";
import { introPosition } from "../constants/textBubblesPositionCSS";
import { BaseTextBubble } from "./BaseTextBubble";

const IntroBribeTextBubble: React.FC = () => {
  const dispatch = useAppDispatch();

  const onIntroBribeClick = async () => {
    dispatch(setForegroundStageId(ForegroundStageId.BRIBE));
    dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
    await dispatch(
      setGaurdAnimationVariant(GuardVariantNames.FLY_TO_MID)
    ).unwrap();
    dispatch(setTextBubbleIndex(TextBubbleIndex.BEFORE_MINT));
    await dispatch(
      setGaurdAnimationVariant(GuardVariantNames.IDLE_HOVER)
    ).unwrap();
  };

  return (
    <BaseTextBubble positionCSS={introPosition} direction={"right"}>
      <EVGenericText text={introOneBubbleText} />
      <div className="flex justify-start align-center m-line-h">
        <EVGenericText text={introTwoBubbleText} extraClassNames={"m-line-h"} />
        <EVGenericText
          text={introCtaBubbleText}
          extraClassNames={"clickable-text xs-ml underline m-line-h"}
          onClick={onIntroBribeClick}
        />
      </div>
    </BaseTextBubble>
  );
};

export default IntroBribeTextBubble;
