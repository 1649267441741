import React from "react";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import ForegroundStageId from "../../../../constants/ForegroundStageId";
import { useAppDispatch } from "../../../../state/hooks";
import { disconnectWallet } from "../../../../state/wallet-info-state";
import { resetBribe } from "../../../bribe/state";
import {
  setCurrentPlayedVideo,
  setForegroundStageId,
} from "../../../main-design-system/state";
import {
  ctaOneText,
  ctaTwoText,
  subtitleText,
  titleText,
} from "./constants/NoQualifiedNftsTexts";

const buttonsWidth = 80 + "%";

const InvalidWalletErrorsNoQualifiedNfts = () => {
  return (
    <div className="invalid-wallet-errors-no-qualified-nfts-container flex column bg-grad-ltr align-start m-pad round-corners-narrow">
      <Titles />
      <CTAs />
    </div>
  );
};

const Titles: React.FC = () => (
  <>
    <EVGenericText
      text={titleText}
      size={"large"}
      type={"h2"}
      weight={"bold"}
      extraClassNames={"s-mb"}
    />
    <EVGenericText text={subtitleText} type={"h3"} extraClassNames={"m-mb"} />
  </>
);

const CTAs: React.FC = () => {
  const dispatch = useAppDispatch();

  const onChangeWalletClick = () => {
    dispatch(resetBribe());
    dispatch(disconnectWallet());
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedBlueIdle",
      })
    );
  };
  const onCheckNFTQualificationClick = () => {
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedBlueIdle",
      })
    );
    dispatch(setForegroundStageId(ForegroundStageId.OPENSEA_NFT_VALIDATOR));
    dispatch(resetBribe());
    dispatch(disconnectWallet());
  };

  return (
    <>
      <EVGenericButton
        text={ctaOneText}
        extraClassNames={"m-mb"}
        customCSS={{
          width: buttonsWidth,
        }}
        cb={() => window.open("https://opensea.io/collection/eternalvikings", "_self")}
      />
      {/*<EVGenericButton
        text={ctaTwoText}
        isDark={true}
        customCSS={{
          width: buttonsWidth,
        }}
        cb={onCheckNFTQualificationClick}
    />*/}
    </>
  );
};

export default InvalidWalletErrorsNoQualifiedNfts;
