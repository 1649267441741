import React from 'react';
import EVGenericButton from '../../../../components/ev-generic-components/EVGenericButton';
import EVGenericText from '../../../../components/ev-generic-components/EVGenericText';
import ForegroundStageId from '../../../../constants/ForegroundStageId';
import { useAppDispatch } from '../../../../state/hooks';
import { disconnectWallet } from '../../../../state/wallet-info-state';
import { setForegroundStageId } from '../../../main-design-system/state';
import { ctaOneText, subtitleText, titleText } from './constants/notRegisteredText';

const buttonsWidth = 80 + '%';

const NoRegistered = () => {
  return (
    <div className='invalid-wallet-errors-no-qualified-nfts-container flex column bg-grad-ltr align-start m-pad round-corners-narrow'>
      <Titles />
      <CTAs />
    </div>
  );
};

const Titles: React.FC = () => (
  <>
    <EVGenericText text={titleText} size={'large'} type={'h2'} weight={'bold'} extraClassNames={'s-mb'} />
    <EVGenericText text={subtitleText} type={'h3'} extraClassNames={'m-mb'} />
  </>
);

const CTAs: React.FC = () => {
  const dispatch = useAppDispatch();

  const onBackButton = () => {
    dispatch(disconnectWallet());
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  return (
    <>
      <EVGenericButton
        text={ctaOneText}
        extraClassNames={'m-mb'}
        customCSS={{
          width: buttonsWidth,
        }}
        cb={onBackButton}
      />
    </>
  );
};

export default NoRegistered;
