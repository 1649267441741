import React from "react";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import { helperText } from "../../../../constants/sacrificeSectionText";
import "./SacrificeSectionContentFooter.scss";

const SacrificeSectionContentFooter: React.FC = () => (
  <div className="sacrifice-section-content-footer-container">
    <EVGenericText text={helperText} />
  </div>
);
export default SacrificeSectionContentFooter;
