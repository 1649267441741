import { forwardRef } from "react";
import { selectGeneralInfo } from "../../state/general-info-state";
import { useAppSelector } from "../../state/hooks";

export type AudioPlayerProps = {
  audioSrc: string;
  loop?: boolean;
  shouldPlayOnMount?: boolean;
};

const AudioPlayer = forwardRef<HTMLAudioElement, AudioPlayerProps>(
  ({ audioSrc, shouldPlayOnMount = true, loop = false }, ref) => {
    const audioMuted = useAppSelector(
      selectGeneralInfo("audioMuted")
    ) as boolean;

    return (
      <audio
        ref={ref}
        src={audioSrc}
        autoPlay={shouldPlayOnMount}
        muted={audioMuted}
        loop={loop}
      />
    );
  }
);

export default AudioPlayer;
