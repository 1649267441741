import React from "react";

type EVVideoProps = {
  srcMp4: string;
  srcWebm?: string;
  onVideoEnded?: () => void;
  id: string;
};

const EVVideo: React.FC<EVVideoProps> = ({
  id,
  srcMp4,
  srcWebm,
  onVideoEnded,
}) => (
  <video
    id={id}
    src={srcMp4}
    muted
    autoPlay
    loop={!onVideoEnded}
    onEnded={onVideoEnded}
    preload="metadata"
  >
    {srcWebm && <source src={srcWebm} type="video/webm" />}
    <source src={srcMp4} type="video/mp4" />
  </video>
);

export default EVVideo;
