import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { titleText, subtitleText } from "../../constants/selectNftTexts";
import "./SelectNftHeader.scss";

const SelectNftHeader: React.FC = () => (
  <div className="select-nft-header flex column space-evenly align-center s-pad">
    <EVGenericText
      type={"h2"}
      weight={"bold"}
      size={"x-large"}
      text={titleText}
      extraClassNames={"s-mb"}
    />
    <EVGenericText size={"medium"} type={"h3"} text={subtitleText} />
  </div>
);

export default SelectNftHeader;
