import { createAsyncThunk } from "@reduxjs/toolkit";
import OutputI from "../../../../interfaces/Output";
import ReducerSliceNames from "../../../../state/constants/ReducerSliceNames";
import { RootState } from "../../../../state/store";
import { GuardVariantNames } from "../../components/gaurd-entity/animations/variants";

export const setGuardAnimationVariantActionName = 'setGuardAnimationVariant';
const setGaurdAnimationVariant = createAsyncThunk(
  `${ReducerSliceNames.BRIBE}/${setGuardAnimationVariantActionName}`,
  async (newGuardAnimationVariant: GuardVariantNames, { getState }): Promise<OutputI<GuardVariantNames>> => {
    try {
        const guardControl = (getState() as RootState).bribe.guardControl;
        if(!guardControl) throw new Error("guardControl is undefined");
        await guardControl.start(newGuardAnimationVariant);
        return { err: false, output: newGuardAnimationVariant };
    } catch (error) {
      const msg = `state/actions/index.ts::setGaurdAnimationVariant():: Failed to set guard animation variant! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default setGaurdAnimationVariant;