import React from 'react';
import './ShowCounter.scss';
import DateTimeDisplay, { DateTimeDisplayType } from './DateTimeDisplay';
import EVGenericText from '../ev-generic-components/EVGenericText';

export type ShowCounterProps = {
  hours: DateTimeDisplayType;
  minutes: DateTimeDisplayType;
  seconds: DateTimeDisplayType;
};

const ShowCounter: React.FC<ShowCounterProps> = ({ hours, minutes, seconds }) => (
  <div className='countdown-display-show-counter flex row align-center m-mb justify-start'>
    {[hours, minutes, seconds].map((d, index) => {
      const isFirst = !index;
      const { highlight, value, valueTypeName } = d;
      return (
        <>
          {!isFirst && (
            <EVGenericText
              text={':'}
              customCSS={{
                fontWeight: '200',
                fontSize: '3rem',
                letterSpacing: '0.275rem',
              }}
            />
          )}
          <DateTimeDisplay key={index} highlight={highlight} value={value} valueTypeName={valueTypeName} />
        </>
      );
    })}
  </div>
);

export default ShowCounter;
