import React from "react";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import {
  subtitleBeforeMintText,
  subtitleAfterMintText,
  titleText,
} from "../../../../constants/sacrificeSectionText";
import { MintState } from "../../../../SacrificeSection";
import { MintStateProp } from "../../SacrificeSectionContent";

const SacrificeSectionContentHeader: React.FC<MintStateProp> = ({
  mintState,
}) => (
  <div className="sacrifice-section-content-header-container flex column space-between align-start">
    <EVGenericText
      text={
        mintState !== MintState.AFTER
          ? subtitleBeforeMintText
          : subtitleAfterMintText
      }
      type={"h3"}
      size={"medium"}
    />
    <EVGenericText
      text={titleText}
      type={"h2"}
      customCSS={{ fontFamily: "Vikings", fontSize: "5.5rem" }}
      extraClassNames={"s-mt"}
    />
  </div>
);
export default SacrificeSectionContentHeader;
