import React from "react";
import "./OpenSeaNftValidatorInput.scss";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { selectOpenSeaNftValidator, setInput } from "../../state";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import {
  formInputInvalidTxt,
  formInputPlaceholderTxt,
} from "../../constants/OpenSeaNftValidatorTexts";

export type OpenSeaNftValidatorInputProps = {
  urlValid: boolean;
};

const OpenSeaNftValidatorInput: React.FC<OpenSeaNftValidatorInputProps> = ({
  urlValid,
}) => {
  const dispatch = useAppDispatch();
  const input = useAppSelector(
    selectOpenSeaNftValidator("inputValue")
  ) as string;

  const onValueChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    dispatch(setInput(e.currentTarget.value));
  };

  return (
    <div className="opensea-nft-validator-input-container flex column align-start m-mb">
      <input
        value={input}
        onChange={onValueChange}
        type="text"
        placeholder={formInputPlaceholderTxt}
        className={`opensea-nft-validator-input-field round-corners`}
        style={{ borderColor: urlValid ? "white" : "red" }}
      />
      {!urlValid && (
        <EVGenericText
          text={formInputInvalidTxt}
          customCSS={{
            color: "red",
            fontSize: "1.25rem",
            marginTop: "0.5rem",
          }}
        />
      )}
    </div>
  );
};

export default OpenSeaNftValidatorInput;
