import { MintState } from "../../../SacrificeSection";
import { PopupContentEnum } from "../../content/components/ctas/contants/sacrificePopupContent";

const shouldClosePopups = (
  mintState: MintState,
  popupIndex: PopupContentEnum
): boolean => {
  return (
    (mintState === MintState.SACRIFICE_POPUP &&
      popupIndex === PopupContentEnum.SACRIFICE) ||
    (mintState === MintState.SACRIFICE_STAKE_POPUP &&
      popupIndex === PopupContentEnum.SACRIFICE_STAKE)
  );
};

export default shouldClosePopups;
