import { AnimatePresence, motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { UnmuteIcon, MuteIcon } from '../../../../assets/images';
import { selectGeneralInfo, toggleAudioMuted, toggleFirstPlay } from '../../../../state/general-info-state';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import './MuteAudioButton.scss';

const MuteAudioButton: React.FC<{ isVisible: boolean }> = ({ isVisible }) => {
  const dispatch = useAppDispatch();

  const audioMuted = useAppSelector(selectGeneralInfo('audioMuted')) as boolean;
  const firstPlay = useAppSelector(selectGeneralInfo('firstPlay')) as boolean;

  const getCurrentImg = useCallback(() => {
    return audioMuted ? MuteIcon : UnmuteIcon;
  }, [audioMuted]);

  const [currentImg, setCurrentImg] = useState(getCurrentImg());

  useEffect(() => {
    setCurrentImg(getCurrentImg);
  }, [audioMuted, getCurrentImg, firstPlay]);

  const onMuteToggleButtonClick = () => {
    if (!firstPlay && audioMuted) dispatch(toggleFirstPlay(true));
    dispatch(toggleAudioMuted(!audioMuted));
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.img
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 0.75 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.75 },
          }}
          src={currentImg}
          alt='mute'
          onClick={onMuteToggleButtonClick}
          id={'mute-audio-icon'}
        />
      )}
    </AnimatePresence>
  );
};

export default MuteAudioButton;
