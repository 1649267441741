import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";

export const HowItWorksParagraph: React.FC<HowItWorksParagraphProps> = ({
  stepIndex,
  children,
}) => (
  <div className="how-it-works-paragraph-container flex justify-center align-center">
    <EVGenericText
      text={stepIndex.toString()}
      size={"huge"}
      customCSS={{
        fontFamily: "Vikings",
      }}
    />
    <div className="how-it-works-paragraph-text flex column justify-start align-start s-ml xs-mb">
      {children}
    </div>
  </div>
);

export type HowItWorksParagraphProps = {
  stepIndex: number;
  children: JSX.Element | JSX.Element[];
};
