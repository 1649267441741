import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../../state/store';
import OpenseaNftValidatorResult from '../api/interfaces/OpenseaNftValidatorResult';
import { OpenSeaNftValidatorStageId } from "../constants/OpenSeaNftValidatorStageId";
import StateStatusNames from '../../../interfaces/StateStatusNames';
import ReducerSliceNames from '../../../state/constants/ReducerSliceNames';
import { sendOpenseaNftValidationAsyncAction } from './actions/sendOpenseaNftValidationAsyncAction';

export interface OpenSeaNftValidatorState {
  validationResult: OpenseaNftValidatorResult
  inputValue: string
  stageId: OpenSeaNftValidatorStageId
  status: StateStatusNames;
}

export const validationResultInitState = {
  valid: true,
  imgURL: '',
  name: '' // (Format: name#id, Example: MYNFT#123 )
}

  
export const openSeaNftValidatorSliceInitState: OpenSeaNftValidatorState = {
    validationResult: validationResultInitState,
    inputValue: '',
    stageId: OpenSeaNftValidatorStageId.NULL,
    status: 'idle'
};

const openSeaNftValidatorSlice = createSlice({
  name: ReducerSliceNames.OPEN_SEA_NFT_VALIDATOR,
  initialState: openSeaNftValidatorSliceInitState,
  reducers: {
    setInput: (state, action: PayloadAction<string>)=>{
        state.inputValue = action.payload;
    },
    setStageId: (state, action: PayloadAction<OpenSeaNftValidatorStageId>) => {
        state.stageId = action.payload;
    },
    setValidationResult: (state, action: PayloadAction<OpenseaNftValidatorResult>) => {
      state.validationResult = action.payload;
    }
  },
  extraReducers:(builder) => {
    builder
      .addCase(sendOpenseaNftValidationAsyncAction.pending, (state) => {
        state.status = 'loading';
        state.inputValue = '';
      })
      .addCase(sendOpenseaNftValidationAsyncAction.fulfilled, (state, action) => {
        state.validationResult = action.payload.output ? action.payload.output : validationResultInitState;
        state.status = 'idle';
        // state.stageId = OpenSeaNftValidatorStageId.RESULT;
      })
      .addCase(sendOpenseaNftValidationAsyncAction.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export type OpenSeaNftValidatorSelectors = 'stageId' | 'inputValue' | 'validationResult' | 'status';
export const selectOpenSeaNftValidator = (selector: OpenSeaNftValidatorSelectors)=>(state: RootState) => state.openSeaNftValidator[selector];
export const {setInput, setStageId, setValidationResult} = openSeaNftValidatorSlice.actions;
export default openSeaNftValidatorSlice.reducer;
