import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getSignerAddress from "../utils/getSignerAddress";

import getMintedEvAmount from "../get-minted-ev-amount";

/**
 * @KASH
 * @TODO - Please make a check if the user has **Only One** nft and **Only** if its **currently staked**.
 * @dev This function is called after the sacrifice / sacrifice & stake process,
 * the user will have the option to go back to the start to mint again.
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @returns A bool indicating ig the user can mint again.
 */
const checkCanMintAgainEthers = async (provider: ethers.providers.Web3Provider): Promise<OutputI<boolean>> => {
    try {
        const address = await getSignerAddress(provider)
        let mintedEvs = await getMintedEvAmount(provider, address)
        if (!mintedEvs.output && mintedEvs.output !== 0)
            return { err: true, output: false }
        const output = mintedEvs.output < 5
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `check-can-mint-again/index.ts::checkCanMintAgainEthers():: Failed to check if wallet can mint again! || \n${error}`};
    }
}

export default checkCanMintAgainEthers;