import { ethers } from "ethers";
import Output from "../../../../../interfaces/Output";
import getMintedEVNftId from "../../../../../services/ethers/get-minted-ev-nft-id";
import getSignerAddress from "../../../../../services/ethers/utils/getSignerAddress";
import { generateAfterMintEVNftName } from "../constants/EVNftName";

const getMintedEVNftName = async (provider: ethers.providers.Web3Provider): Promise<Output<string>> => {
      try {
        const res = await getMintedEVNftId(provider, await getSignerAddress(provider));
        if (res.err) throw new Error(res.msg);
        if ( !res.output ) throw new Error("result is invalid!");
        const output = generateAfterMintEVNftName(res.output);
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `getMintedEVNftName.ts::getMintedEVNftName():: Failed to get minted nft name! || \n${error}`};
    }
}

export default getMintedEVNftName;