import React, { useCallback, useEffect, useState } from "react";
import "./ForegroundRenderer.scss";
import ForegroundStageId from "../../../../constants/ForegroundStageId";
import { useAppSelector } from "../../../../state/hooks";
import FAQ from "../../../opensea-nft-validator/components/faq/FAQ";
import WelcomeSection from "../../../welcome-screen/WelcomeSection";
import { selectMainLayoutSystem } from "../../state";
import useDisplaySizeListener from "../../../../hooks/useDisplaySizeListener";
import MobileDisplay from "../mobile-display/MobileDisplay";
import { AnimatePresence, AnimationControls, motion } from "framer-motion";
import SelectNft from "../../../select-nft/SelectNft";
import SacrificeSection from "../../../sacrifice-section/SacrificeSection";
import Bribe from "../../../bribe-eth-mint/Bribe";
import { selectBribe } from "../../../bribe/state";
import {
  resetGuardAnimation,
  runGuardAnimation,
} from "../../../bribe/components/gaurd-entity/animations";
import { GuardVariantNames } from "../../../bribe/components/gaurd-entity/animations/variants";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { selectWalletInfo } from "../../../../state/wallet-info-state";

export type ForegroundRendererProps = {
  isVisible: boolean;
};

const ForegroundRenderer: React.FC<ForegroundRendererProps> = ({
  isVisible,
}) => {
  const foregroundStageId = useAppSelector(
    selectMainLayoutSystem("foregroundStageId")
  ) as ForegroundStageId;
  const onForegroundFadeAnimationEnd = useAppSelector(
    selectMainLayoutSystem("onForegroundFadeAnimationEnd")
  ) as () => void;
  const bribeEnabled = useAppSelector(selectBribe("enabled")) as boolean;
  const guardAnimationControls = useAppSelector(
    selectBribe("guardControl")
  ) as AnimationControls;
  const [showBribe, setShowBribe] = useState(false);

  const showWelcomeSection =
    foregroundStageId === ForegroundStageId.WELCOME_SECTION ||
    foregroundStageId === ForegroundStageId.OPENSEA_NFT_VALIDATOR;

  const checkShowBribe = useCallback(
    () =>
      (foregroundStageId === ForegroundStageId.WELCOME_SECTION ||
        foregroundStageId === ForegroundStageId.BRIBE) &&
      bribeEnabled,
    [foregroundStageId, bribeEnabled]
  );

  const displayType = useDisplaySizeListener();
  const address = useAppSelector(selectWalletInfo("address")) as string;

  const runGuardEntranceAnimation = async () => {
    resetGuardAnimation(guardAnimationControls);
    await runGuardAnimation(
      guardAnimationControls,
      GuardVariantNames.SNEAK_HIDDEN
    );
    await runGuardAnimation(guardAnimationControls, GuardVariantNames.SNEAK_IN);
  };

  useEffect(() => {
    if (foregroundStageId === ForegroundStageId.WELCOME_SECTION && bribeEnabled)
      runGuardEntranceAnimation();
  }, [foregroundStageId, checkShowBribe]);

  useEffect(() => {
    setShowBribe(checkShowBribe());
  }, [checkShowBribe]);

  if (displayType === "mobile") return <MobileDisplay />;

  return (
    <AnimatePresence onExitComplete={onForegroundFadeAnimationEnd}>
      {isVisible && (
        <motion.main
          className="foreground-renderer-container"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 0.75 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.75 },
          }}
        >
          <FAQ />
          {!!address && (
            <EVGenericText
              text={address}
              size={"small"}
              extraClassNames={"account-address"}
            />
          )}
          {showWelcomeSection && <WelcomeSection />}
          {foregroundStageId === ForegroundStageId.HALLWAY && <SelectNft />}
          {foregroundStageId === ForegroundStageId.SHRINE && (
            <SacrificeSection />
          )}
          {showBribe && <Bribe />}
        </motion.main>
      )}
    </AnimatePresence>
  );
};

export default ForegroundRenderer;
