import React from "react";
import useCountdown from "../../hooks/useCountdown";
import ShowCounter from "./ShowCounter";

export type CountdownTimerProps = {
  /**
   * A milliseconds representation of a date instance
   */
  targetDate: number;
};

const CountdownTimer: React.FC<CountdownTimerProps> = ({ targetDate }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate);

  return (
    <ShowCounter
      hours={{ value: hours, valueTypeName: "hours" }}
      minutes={{ value: minutes, valueTypeName: "minutes" }}
      seconds={{ value: seconds, valueTypeName: "seconds" }}
    />
  );
};
export default CountdownTimer;
