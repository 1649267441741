import React from "react";
import { MintState } from "../../../../SacrificeSection";
import { MintStateProp } from "../../SacrificeSectionContent";
import SacrificeSectionContentCTAsAfterSacrifice from "./SacrificeSectionContentCTAsAfterSacrifice";
import SacrificeSectionContentCTAsBeforeSacrifice from "./SacrificeSectionContentCTAsBeforeSacrifice";
import "./SacrificeSectionContentCTAs.scss";

const SacrificeSectionContentCTAs: React.FC<MintStateProp> = ({
  mintState,
  setMintState,
}) => {
  const className = mintState === MintState.BEFORE ? "before" : "after";

  return (
    <div
      className={`sacrifice-section-content-ctas-container-${className} flex space-between align-center`}
    >
      {mintState !== MintState.AFTER && (
        <SacrificeSectionContentCTAsBeforeSacrifice
          mintState={mintState}
          setMintState={setMintState}
        />
      )}
      {mintState === MintState.AFTER && (
        <SacrificeSectionContentCTAsAfterSacrifice />
      )}
    </div>
  );
};
export default SacrificeSectionContentCTAs;
