import React, { useEffect } from "react";
import { BGVideoNames } from "../../assets/videos";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  selectMainLayoutSystem,
  setCurrentPlayedVideo,
} from "../main-design-system/state";
import SelectNftContent from "./components/select-nft-content/SelectNftContent";
import SelectNftFooter from "./components/select-nft-footer/SelectNftFooter";
import SelectNftHeader from "./components/select-nft-header/SelectNftHeader";
import "./SelectNft.scss";

const SelectNft: React.FC = () => {
  const currentVideo = useAppSelector(
    selectMainLayoutSystem("currentPlayedVideo")
  ) as BGVideoNames;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentVideo !== "HallwayIdle")
      dispatch(
        setCurrentPlayedVideo({
          videoName: "HallwayIdle",
          onVideoEnded: undefined,
        })
      );
  }, []);

  return (
    <section className="select-nft-container flex-center column">
      <section className="select-nft-inner-container flex-center column">
        <div className="select-nft-inner-container flex column align-center space-between bg-grad-down round-corners-narrow">
          <SelectNftHeader />
          <SelectNftContent />
        </div>
        <SelectNftFooter />
      </section>
    </section>
  );
};

export default SelectNft;
