import React, { useEffect, useState } from "react";
import NftCard from "../../../../components/nft-card/NftCard";
import { INftDisplay } from "../../../../interfaces/NftData";
import { useAppSelector } from "../../../../state/hooks";
import { selectNftData } from "../../state";
import "./SelectNftItem.scss";

export interface SelectNftItemProps extends INftDisplay {
  onClick: () => void;
}

const SelectNftItem: React.FC<SelectNftItemProps> = ({
  imgURL,
  name,
  onClick,
}) => {
  const [selected, setSelected] = useState(false);
  const selectedNftName = useAppSelector(selectNftData("name"));

  useEffect(() => {
    setSelected(name === selectedNftName);
  }, [selectedNftName]);

  return (
    <div className={`select-nft-item${selected ? "-selected" : ""}`}>
      <NftCard imgURL={imgURL} name={name} onClick={onClick} />
    </div>
  );
};

export default SelectNftItem;
