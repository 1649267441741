import { createAsyncThunk } from '@reduxjs/toolkit';
import { WalletInfoSliceStateI } from '..';
import { toggleBribe } from '../../../features/bribe/state';
import OutputI from '../../../interfaces/Output';
import checkCanBribe from '../../../services/ethers/check-can-bribe';
import connectWallet from '../../../services/ethers/connect-wallet';
import getSignerAddress from '../../../services/ethers/utils/getSignerAddress';
import ReducerSliceNames from '../../constants/ReducerSliceNames';
import getWalletInfoRequest from '../api';

export const connectWalletActionName = 'ConnectWallet';
const connectWalletAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${connectWalletActionName}`,
  async (_, { dispatch }): Promise<OutputI<WalletInfoSliceStateI>> => {
    try {
      const connectWalletResult = await connectWallet();
      if(connectWalletResult.err) throw new Error(connectWalletResult.msg);
      if(!connectWalletResult.output) throw new Error("provider is undefined");
      const getWalletInfoResponse = await getWalletInfoRequest(connectWalletResult.output, await getSignerAddress(connectWalletResult.output));
      if(getWalletInfoResponse.err) throw new Error(getWalletInfoResponse.msg);
      if(!getWalletInfoResponse.output) throw new Error("Failed to get wallet info");
      const checkCanBribeResponse = await checkCanBribe(connectWalletResult.output);
      if(checkCanBribeResponse.err) throw new Error(checkCanBribeResponse.msg);
      if(checkCanBribeResponse.output === undefined) throw new Error("Can bribe check response is undefined");
      //dispatch(toggleBribe(checkCanBribeResponse.output));
      const address = await connectWalletResult.output.getSigner().getAddress();
      if(address === undefined) throw new Error("address is undefined");
      const output = {
        provider: connectWalletResult.output,
        qualifiedNfts: getWalletInfoResponse.output.qualifiedNfts,
        walletStatusId: getWalletInfoResponse.output.walletStatusId,
        address,
        userBalance: getWalletInfoResponse.output.userBalance
      } as WalletInfoSliceStateI
      return { err: false, output };
    } catch (error) {
      const msg = `connectWalletAsyncAction.ts::connectWalletAsyncAction():: Failed to connect wallet! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default connectWalletAsyncAction;