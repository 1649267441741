import { motion, MotionStyle } from "framer-motion";
import React from "react";
import "./BaseTextBubble.scss";

export const BaseTextBubble: React.FC<{
  children: JSX.Element | JSX.Element[] | undefined;
  positionCSS: MotionStyle;
  direction: "left" | "right";
}> = ({ children, positionCSS, direction }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 1 } }}
    exit={{ opacity: 0, transition: { duration: 1 } }}
    className={`text-bubble-container-${direction} m-pad`}
    style={positionCSS}
  >
    {children && children}
  </motion.div>
);
