import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import INftData from "../../../interfaces/NftData";

import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

/**
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The blessed tokens of the user
 */
const getBlessedTokensOfUser = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<INftData[]>> =>{
    try {
        const blessedTokenContract = await getContractInstance(provider, ContractIdentifiers.core.EternalVikingsWhitelistToken);
        const tokensOfUser = await blessedTokenContract.tokensOfOwner(walletAddress);
        const tokensOfUserAsQualifiedNfts = tokensOfUser.map((t: ethers.BigNumber) => {
            return {
                name: "Blessed Token #" + t.toNumber(),
                imgURL: process.env.REACT_APP_WL_TOKEN_IMG_URL,
                swapInfo: {
                    address: blessedTokenContract.address,
                    tokenId: t.toNumber(),
                    contractType: 'ERC721'
                }
            }
        })
        return {err: false, output: tokensOfUserAsQualifiedNfts};
    } catch (error) {
        return {err: true, msg: `get-blessed-tokens-of-user/index.ts::getBlessedTokensOfUser():: Failed to get blessed tokens of user! || \n${error}`};
    }
}

export default getBlessedTokensOfUser;