import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnimationControls } from 'framer-motion';
import StateStatusNames from '../../../interfaces/StateStatusNames';
import ReducerSliceNames from '../../../state/constants/ReducerSliceNames';
import { RootState } from '../../../state/store';
import { GuardVariantNames } from '../components/gaurd-entity/animations/variants';
import { TextBubbleIndex } from '../components/text-bubble/constants/TextBubbleIndex';
import setGaurdAnimationVariant from './actions';

export interface BribeStateI {
  status: StateStatusNames;
  enabled: boolean;
  showGuard: boolean;
  textBubbleIndex: TextBubbleIndex;
  guardVariantName: GuardVariantNames;
  guardControl: AnimationControls;
}

export const bribeInitState: BribeStateI = {
  enabled: false,
  showGuard: false,
  textBubbleIndex: TextBubbleIndex.NULL,
  guardVariantName: GuardVariantNames.SNEAK_HIDDEN,
  guardControl: {} as AnimationControls,
  status: 'idle'
};

const extraReducers = (builder: ActionReducerMapBuilder<BribeStateI>)=>{
    builder
    .addCase(setGaurdAnimationVariant.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(setGaurdAnimationVariant.fulfilled, (state, action) => {
      if(!action.payload.output){
        state.status = 'failed'
        state.textBubbleIndex = TextBubbleIndex.NULL
        state.guardVariantName = GuardVariantNames.SNEAK_HIDDEN
      }
      else{
        state.status = 'idle';
        state.guardVariantName = action.payload.output
        }
      })
    .addCase(setGaurdAnimationVariant.rejected, (state) => {
      state.status = 'failed'
      state.textBubbleIndex = TextBubbleIndex.NULL
      state.guardVariantName = GuardVariantNames.SNEAK_HIDDEN
    })
  }

const bribeSlice = createSlice({
  name: ReducerSliceNames.BRIBE,
  initialState: bribeInitState,
  reducers: {
    setGuardControl: (state, action: PayloadAction<AnimationControls>)=>{
      state.guardControl = action.payload;
    },
    toggleBribe: (state, action: PayloadAction<boolean>)=>{
      state.enabled = action.payload;
    },
    toggleGuard: (state, action: PayloadAction<boolean>)=>{
      state.showGuard = action.payload;
    },
    setTextBubbleIndex: (state, action: PayloadAction<TextBubbleIndex>) => {
      state.textBubbleIndex = action.payload;
    },
    resetBribe: (state) => {
      state.enabled = false;   
      state.showGuard = false;
      state.textBubbleIndex = TextBubbleIndex.NULL;
      state.guardVariantName = GuardVariantNames.SNEAK_HIDDEN;
      state.guardControl = {} as AnimationControls;
    },
  },
  extraReducers
});

export const { toggleBribe, setTextBubbleIndex, toggleGuard, resetBribe, setGuardControl } = bribeSlice.actions;
export type BribeSelectors = 'enabled' | 'showGuard' | 'textBubbleIndex' | 'guardVariantName' | 'guardControl';
export const selectBribe = (selector: BribeSelectors)=>(state: RootState) => state.bribe[selector];
export const getBribe = ()=>(state: RootState) => state.bribe;
export default bribeSlice.reducer;
