const openTermsOfUseNewTab = () => {
  window.open(
    'https://uploads-ssl.webflow.com/6231cc0b1edd28a5c5e74800/632997cf47dd204419c4f9dd_Terms%20of%20Use%20-%20EV.pdf',
    '_blank',
    'noopener,noreferrer'
  );
  window.location.reload();
};

export default openTermsOfUseNewTab;
