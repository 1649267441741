import React from "react";
import "./SelectNftContent.scss";
import SelectNftGrid from "./SelectNftGrid";

const SelectNftContent: React.FC = () => (
  <div className="select-nft-content flex-center">
    <SelectNftGrid />
  </div>
);

export default SelectNftContent;
