import React from "react";
import "./EVLogo.scss";
import { EternalVikingsLogo } from "../../assets/images";
import { AnimatePresence, motion } from "framer-motion";
import ReactGA from "react-ga";

export type EVLogoProps = {
  isVisible: boolean;
};

const EVLogo: React.FC<EVLogoProps> = ({ isVisible }) => {
  const refreshPage = () => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: "click-on-ev-logo",
        category: "button",
      });
    }
    window.location.reload();
  };

  return (
    <AnimatePresence>
      {isVisible && (
        <motion.img
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: { duration: 0.75 },
          }}
          exit={{
            opacity: 0,
            transition: { duration: 0.75 },
          }}
          src={EternalVikingsLogo}
          alt="eternal-vikings-medium"
          id="eternal-vikings-logo"
          onClick={refreshPage}
        />
      )}
    </AnimatePresence>
  );
};

export default EVLogo;
