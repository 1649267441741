import CountdownDisplayIndex from "./CountdownDisplayIndex";
import ICountdownContent from "../interfaces/ICountdownContent";

export default new Map<CountdownDisplayIndex, ICountdownContent>([
    [CountdownDisplayIndex.WHITELIST_MINT, {
        title: "Blessed Ceremony is NOW LIVE and will end in",
        targetDate: parseInt(process.env.REACT_APP_PREMINT_MINT_DATE)
    }],
    [CountdownDisplayIndex.PRE_MINT, {
        title: "Premint Winners Ceremony is NOW LIVE and will end in",
        targetDate: parseInt(process.env.REACT_APP_PUBLIC_MINT_DATE)
    }],
    [CountdownDisplayIndex.PUBLIC_MINT, {
        title: "You are all invited by the Gods to sacrifice an NFT in\nexchange for an Eternal Vikings Genesis NFT",
        targetDate: 0
    }],
])