import { ethers } from "ethers";
import * as c from "../../../contracts/hardhat_contracts.json";

interface HardhatContracts {
    [key: string] : {
        [key: string] : object
    }
}
interface HardhatContract {
    [key: string] : string
}
let Contracts: HardhatContracts = c as HardhatContracts;

/**
 * @dev Creates an ethers contract instance based on the specified contract identifier
 * @param provider - A Web3Provider signed by the user's connected wallet.
 * @param contractIdentifier - A string identifying a web3 smart contract
 * @returns Ethers contract object
 */
const getContractInstance = async (provider: ethers.providers.Web3Provider, contractIdentifier: string): Promise<ethers.Contract> => {
    let { chainId: chainIdNum } = await provider.getNetwork();
    let chainId = chainIdNum.toString();

    let contractsAtChainId = Contracts[chainId];
    if (!contractsAtChainId)
        throw new Error(`No contracts deployed on chain ${chainId}`);
    if (Object.keys(contractsAtChainId).length === 0 )
        throw new Error(`No contracts deployed on chain ${chainId}`);
    
    let primaryNetworkName = Object.keys(contractsAtChainId)[0];
    let contractsAtPrimaryNetworkName = contractsAtChainId[primaryNetworkName] as HardhatContracts;

    if (!contractsAtPrimaryNetworkName)
        throw new Error(`No contracts deployed on chain ${chainId} at network name ${primaryNetworkName}`);
    if (Object.keys(contractsAtPrimaryNetworkName).length === 0 )
        throw new Error(`No contracts deployed on chain ${chainId} at network name ${primaryNetworkName}`);
    
    let contracts = contractsAtPrimaryNetworkName["contracts"];
    if (!contracts)
        throw new Error(`No contracts deployed on chain ${chainId} at network name ${primaryNetworkName}`);
    if (Object.keys(contracts).length === 0 )
        throw new Error(`No contracts deployed on chain ${chainId} at network name ${primaryNetworkName}`);

    let contractAtSpecifiedIdentifier = contracts[contractIdentifier] as HardhatContract;
    if (!contractAtSpecifiedIdentifier)
        throw new Error(`No contract found at ${contractIdentifier} on network ${chainId} at network name ${primaryNetworkName}`);
    if (Object.keys(contractAtSpecifiedIdentifier).length === 0 )
        throw new Error(`No contract found at ${contractIdentifier} on network ${chainId} at network name ${primaryNetworkName}`);

    return new ethers.Contract(contractAtSpecifiedIdentifier["address"], contractAtSpecifiedIdentifier["abi"], provider);
};
export default getContractInstance;