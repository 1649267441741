import React from "react";

type SeparateLineProps = {
  legnthCSSDefinition: string;
  isHorizontal?: boolean;
};

const SeparateLine: React.FC<SeparateLineProps> = ({
  legnthCSSDefinition,
  isHorizontal = true,
}) => {
  const stlye = {
    height: `${isHorizontal ? "0.5px" : legnthCSSDefinition}`,
    width: `${isHorizontal ? legnthCSSDefinition : "0.5px"}`,
    background:
      "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
    borderImageSource:
      "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%)",
  };
  return <div style={stlye} />;
};

export default SeparateLine;
