import React, { useEffect, useState } from "react";
import { UnrevealedEV } from "../../../../assets/images";
import NftCard from "../../../../components/nft-card/NftCard";
import { useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import { getSelectedNftData } from "../../../select-nft/state";
import { MintState } from "../../SacrificeSection";
import { beforeMintEVNftName } from "./constants/EVNftName";
import getMintedEVNftName from "./utils/getMintedEVNftName";
import { ethers } from "ethers";
import { motion, AnimatePresence } from "framer-motion";
import { AnimationState } from "./components/float-items-animator/animations/AnimationState";
import {
  evFloatItemVariants,
  nftFloatItemVariants,
} from "./components/float-items-animator/animations/variants";
import "./SacrificeSectionSwapDisplay.scss";

export type SacrificeSectionSwapDisplayProps = {
  mintState: MintState;
};

const SacrificeSectionSwapDisplay: React.FC<
  SacrificeSectionSwapDisplayProps
> = ({ mintState }) => {
  const [EVNftName, setEVNftName] = useState(beforeMintEVNftName);
  const { name, imgURL } = useAppSelector(getSelectedNftData());
  const [animationState, setAnimationState] = useState<AnimationState>(
    AnimationState.IDLE
  );
  const provider = useAppSelector(
    selectWalletInfo("provider")
  ) as ethers.providers.Web3Provider;

  useEffect(() => {
    (async () => {
      if (mintState !== MintState.AFTER) {
        setEVNftName(beforeMintEVNftName);
        return;
      }
      const res = await getMintedEVNftName(provider);
      if (res.err || !res.output) return;
      setEVNftName(res.output);
    })();

    if (mintState === MintState.AFTER)
      setAnimationState(AnimationState.SACRIFICE);
  }, [mintState]);

  return (
    <div className="sacrifice-section-swap-display-container flex justify-center align-end w-all">
      <div className="sacrifice-section-items-boundries l-mb">
        <motion.div
          variants={evFloatItemVariants}
          initial={"initial"}
          animate={animationState}
          onAnimationComplete={() => {
            if (animationState === AnimationState.SACRIFICE) {
              setAnimationState(AnimationState.FRONT_IDLE);
            }
          }}
          className="float-item ev"
        >
          <NftCard imgURL={UnrevealedEV} name={EVNftName} />
        </motion.div>
        <AnimatePresence>
          {animationState === AnimationState.IDLE && (
            <motion.div
              variants={nftFloatItemVariants}
              initial={"initial"}
              animate={"idle"}
              exit={"sacrifice"}
              className="float-item nft"
            >
              <NftCard name={name} imgURL={imgURL} />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};
export default SacrificeSectionSwapDisplay;
