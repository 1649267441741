import React, { useCallback, useEffect, useState } from "react";
import "./MainLayoutSystem.scss";
import BackgroundRenderer from "./components/background-renderer/BackgroundRenderer";
import ForegroundRenderer from "./components/foreground-renderer/ForegroundRenderer";
import EVLogo from "../../components/ev-generic-components/EVLogo";
import { useAppSelector } from "../../state/hooks";
import { selectMainLayoutSystem } from "./state";
import ForegroundStageId from "../../constants/ForegroundStageId";
import MuteAudioButton from "./components/mute-audio-button/MuteAudioButton";
import useDisplaySizeListener from "../../hooks/useDisplaySizeListener";

const MainLayoutSystem: React.FC = () => {
  const mainLayoutStageId = useAppSelector(
    selectMainLayoutSystem("foregroundStageId")
  ) as ForegroundStageId;

  const [foregroundVisible, setForegroundVisible] = useState(true);

  /**
   * @dev This checks if the background video is playing a transition.
   * If it is, we'll hide the UI.
   * @type {boolean}
   */
  const shouldRenderForeground = useCallback(
    () =>
      mainLayoutStageId !== ForegroundStageId.LOADING &&
      mainLayoutStageId !== ForegroundStageId.NULL,
    [mainLayoutStageId]
  );

  useEffect(
    () => setForegroundVisible(shouldRenderForeground()),
    [mainLayoutStageId, shouldRenderForeground]
  );

  const displayType = useDisplaySizeListener();

  return (
    <div className="main-design-system-container">
      <EVLogo isVisible={foregroundVisible} />
      {displayType === "web" && (
        <MuteAudioButton isVisible={foregroundVisible} />
      )}
      <ForegroundRenderer isVisible={foregroundVisible} />
      <BackgroundRenderer />
    </div>
  );
};

export default MainLayoutSystem;
