import React from "react";
import "./App.scss";
import MainLayoutSystem from "./features/main-design-system/MainLayoutSystem";
import Initializations from "./components/initializations/Initializations";
import BackgroundAudioPlayer from "./components/audio-player/components/BackgroundAudioPlayer";

const App: React.FC = () => {
  return (
    <div className="App">
      <Initializations />
      <BackgroundAudioPlayer />
      <MainLayoutSystem />
    </div>
  );
};

export default App;
