import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { selectWalletInfo } from '..';
import OutputI from '../../../interfaces/Output';
import { mintStakeOneNft } from '../../../services/ethers/bribe-mint-flow';
import ReducerSliceNames from '../../constants/ReducerSliceNames';

export const mintStakeOneActionName = 'MintStakeOne';
const mintStakeOneAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.WALLET_INFO}/${mintStakeOneActionName}`,
  async (walletInfoProvider: ethers.providers.Web3Provider): Promise<OutputI<{}>> => {
    try {
      const mintStakeOneResult = await mintStakeOneNft(walletInfoProvider);
      if (mintStakeOneResult.err) throw new Error(mintStakeOneResult.msg);
      return { err: false, output: {} };
    } catch (error) {        
      const msg = `mintStakeOneAsyncAction.ts::mintStakeOneAsyncAction():: Failed to mint and stake an nft! | \n${error}`;
      return { err: true, msg };
    }
  }
);
export default mintStakeOneAsyncAction;
