/**
 * @KASH 
 * 
 * @TODO - This is where the magic happens, in each of these functions you will need to sign the transactions.
 * In each of these transaction functions, you have the provider signed with the user's wallet passed as a parameter.
 * Signer (user's wallet) can be accessed with provider.getSigner();
 * 
 * @provider
 * @type {ethers.providers.Web3Provider}
 * 
 * @selectedNft
 * @type {INftData} 
 */


import { ethers } from "ethers";
import axios from "axios"
import INftData from "../../../interfaces/NftData";
import OutputI from "../../../interfaces/Output";

import getContractInstance from "../utils/getContractInstance";
import getCollectionMerkleProofs from "../utils/getCollectionMerkleProof";
import getPremintMerkleProofs from "../utils/getPremintMerkleProof";
import getERC721Contract from "../utils/getERC721Contract";
import ContractIdentifiers from "../../../constants/contractIdentifiers";
import getSignerAddress from "../utils/getSignerAddress";

import getMintedEVNftId from "../get-minted-ev-nft-id";

export const approveTransfer = async (provider: ethers.providers.Web3Provider, selectedNft: INftData): Promise<OutputI<{}>> => {
    try {
        let {
            address,
            tokenId
        } = selectedNft.swapInfo;
        if (address.toLowerCase() !== process.env.REACT_APP_WL_TOKEN_ADDR.toLowerCase()) {
            const evSacrificeContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
            const erc721Contract = await getERC721Contract(provider, address);
            let getApproved = await erc721Contract.getApproved(tokenId);
            let isApprovedForAll = await erc721Contract.isApprovedForAll(address, evSacrificeContract.address);
            if (getApproved.toLowerCase() !== evSacrificeContract.address.toLowerCase() && !isApprovedForAll) {
                let tx = await erc721Contract.connect(provider.getSigner()).approve(evSacrificeContract.address, tokenId);
                await tx.wait();
            }
        }      
        return {err: false, output: {}}   
    } catch (error) {
        return {err: true, msg: `sacrifice-flow/index.ts::stakeNft():: failed while sending transaction! | \n${error}`}
    }
}

/**
 * @KASH
 * 
 * @dev This is the new function that receives a boolean for the sacrifice / sacrifice & stake txn.
 * 
 * @param provider 
 * @param selectedNft 
 * @param shouldStake 
 * @returns 
 */
export const sacrificeStakeNft = async (provider: ethers.providers.Web3Provider, selectedNft: INftData, shouldStake: boolean): Promise<OutputI<{}>> => {
    try {
        let {
            address,
            tokenId,
            contractType
        } = selectedNft.swapInfo;
        const evSacrificeContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);
        const signerAddress = await getSignerAddress(provider)
        if (address.toLowerCase() !== process.env.REACT_APP_WL_TOKEN_ADDR.toLowerCase()) {          
            let SignatureOutput = await axios.get(process.env.REACT_APP_SIGNATURE_PROVIDER_URL || "", {
                params: {
                    walletAddress: signerAddress,
                    collectionAddress: address,
                    collectionTokenId: tokenId,                    
                }
            });            
            if (!SignatureOutput.data || SignatureOutput.data.err || !SignatureOutput.data.output)
                throw new Error("Signature response error");
            let SignatureData = SignatureOutput.data.output;

            const tx = await evSacrificeContract.connect(provider.getSigner())
                .sacrificeNew(address, tokenId, SignatureData.expirationTime, SignatureData.signature, shouldStake, contractType === "ERC1155");
            await tx.wait();

            try {
                let mintedVikingOutput = await getMintedEVNftId(provider, signerAddress)
                if (!mintedVikingOutput.err && mintedVikingOutput.output) {
                    await axios.post("https://checker.eternalvikings.com/api/v1/public/nft/sacrifice-push-notification", {
                        tokenAddress: address,
                        tokenId,
                        wallet: signerAddress,
                        vikingNFTNumber: mintedVikingOutput.output
                    });
                }
            } catch (e) {}
        } else {
            const tx = await evSacrificeContract.connect(provider.getSigner()).whitelistMint(tokenId, shouldStake);
            await tx.wait();
        }            
        return {err: false, output: {}}   
    } catch (error) {
        return {err: true, msg: `sacrifice-flow/index.ts::stakeNft():: failed while sending transaction! | \n${error}`}
    }
}