import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";

export const FAQParagraph: React.FC<FAQTextProps> = ({ answer, question }) => (
  <div className="faq-paragraph-container flex column m-mb">
    <EVGenericText
      text={question}
      type={"h3"}
      weight={"bold"}
      size={"x-small"}
      extraClassNames={"xs-mb"}
    />
    <EVGenericText text={answer} size={"x-small"} />
  </div>
);
export type FAQTextProps = {
  question: string;
  answer: string;
};
