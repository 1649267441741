import React from "react";
import { MintState } from "../../SacrificeSection";
import SacrificeSectionContentCTAs from "./components/ctas/SacrificeSectionContentCTAs";
import SacrificeSectionContentFooter from "./components/footer/SacrificeSectionContentFooter";
import SacrificeSectionContentHeader from "./components/header/SacrificeSectionContentHeader";
import "./SacrificeSectionContent.scss";

export type MintStateProp = {
  mintState: MintState;
  setMintState: (newMintState: MintState) => void;
};

const SacrificeSectionContent: React.FC<MintStateProp> = ({
  mintState,
  setMintState,
}) => (
  <div className="sacrifice-section-content-container flex justify-start align-start">
    <div className="sacrifice-section-content-inner-container flex column justify-end align-start w-all">
      <div className="sacrifice-section-content-wrapper flex column space-between align-start m-pad round-corners-narrow bg-grad-ltr">
        <SacrificeSectionContentHeader
          mintState={mintState}
          setMintState={(newMintState: MintState) => {
            setMintState(newMintState);
          }}
        />
        <SacrificeSectionContentCTAs
          mintState={mintState}
          setMintState={(newMintState: MintState) => {
            setMintState(newMintState);
          }}
        />
        {mintState === MintState.BEFORE && <SacrificeSectionContentFooter />}
      </div>
    </div>
  </div>
);

export default SacrificeSectionContent;
