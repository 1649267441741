import { motion } from 'framer-motion';
import React from 'react';
import EVGenericText from '../ev-generic-components/EVGenericText';
import './BackButton.scss';

type BackButtonProps = {
  onBackClick: () => void;
};

const BackButton: React.FC<BackButtonProps> = ({ onBackClick }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, transition: { duration: 0.3 } }}
    exit={{ opacity: 0, transition: { duration: 0.3 } }}
    className='back-button-container'
  >
    <EVGenericText text={'Back'} size='medium' extraClassNames={'clickable-text'} onClick={onBackClick} />
  </motion.div>
);

export default BackButton;
