import React, { useCallback, useEffect, useState } from "react";
import getVideoFileByName, { BGVideoNames } from "../../../../assets/videos";
import useDisplaySizeListener from "../../../../hooks/useDisplaySizeListener";
import { useAppSelector } from "../../../../state/hooks";
import {
  BackgroundVideoI,
  backgroundVideoInitState,
  selectMainLayoutSystem,
} from "../../state";
import "./BackgroundRenderer.scss";
import EVVideo from "./components/EVVideo";

const BackgroundRenderer: React.FC = () => {
  const [videoOne, setVideoOne] = useState<BackgroundVideoI>(
    backgroundVideoInitState
  );
  const [videoTwo, setVideoTwo] = useState<BackgroundVideoI>(
    backgroundVideoInitState
  );
  const [isVideoOne, setIsVideoOne] = useState<boolean>(true);

  const currentPlayedVideo = useAppSelector(
    selectMainLayoutSystem("currentPlayedVideo")
  ) as BackgroundVideoI;

  const displaySize = useDisplaySizeListener();

  const renderVideo = useCallback(() => {
    try {
      const res = getVideoFileByName(currentPlayedVideo.videoName);
      if (res.err) throw new Error(res.msg);
      if (!res.output) throw new Error(`Can't read data!`);

      if (isVideoOne) {
        setVideoTwo({
          videoName: res.output as BGVideoNames,
          onVideoEnded: currentPlayedVideo.onVideoEnded,
        });
        setIsVideoOne(false);
      } else {
        setVideoOne({
          videoName: res.output as BGVideoNames,
          onVideoEnded: currentPlayedVideo.onVideoEnded,
        });
        setIsVideoOne(true);
      }
    } catch (error) {
      console.error(
        `background-renderer::BackgroundRenderer():: Failed to render video! | \n${error}`
      );
    }
  }, [currentPlayedVideo, isVideoOne]);

  useEffect(() => {
    renderVideo();
  }, [currentPlayedVideo]);

  return (
    <div
      className={`background-renderer-container-${
        displaySize === "mobile" ? "mobile" : "web"
      }`}
    >
      <EVVideo
        key={1}
        id={`video-${isVideoOne ? "display" : "hidden"}`}
        srcMp4={videoOne.videoName}
        onVideoEnded={videoOne.onVideoEnded}
      />
      <EVVideo
        key={2}
        id={`video-${!isVideoOne ? "display" : "hidden"}`}
        srcMp4={videoTwo.videoName}
        onVideoEnded={videoTwo.onVideoEnded}
      />
    </div>
  );
};

export default BackgroundRenderer;
