import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

/**
 * @KASH
 * @TODO - please find a way to return the user's minted nft. this function is called right after 
 * the user has sacrificed their nft and minted an EV.
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The id of the last EV NFT id the user has minted.
 */
const getMintedEVNftId = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<number>> =>{
    try {
        const evContract = await getContractInstance(provider, ContractIdentifiers.core.EternalVikings);
        const userEVNFTs = await evContract.tokensOfOwner(walletAddress);
        const userEVNFTsNumber = userEVNFTs.map((id: ethers.BigNumber) => id.toNumber());
        userEVNFTsNumber.sort((a: number, b: number) => b - a);
        if (userEVNFTsNumber.length === 0)
            throw new Error();
        const output = userEVNFTsNumber[0];
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `get-next-ev-nft-id/index.ts::getMintedEVNftId():: Failed to get minted ev nft id! || \n${error}`};
    }
}

export default getMintedEVNftId;