import React from "react";
import SelectNftItem from "./SelectNftItem";
import "./SelectNftGrid.scss";
import INftData from "../../../../interfaces/NftData";
import { setSelectedNft } from "../../state";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";

const SelectNftGrid: React.FC = () => {
  const dispatch = useAppDispatch();
  const nfts = useAppSelector(selectWalletInfo("qualifiedNfts")) as INftData[];
  const onCardClick = (nft: INftData) => dispatch(setSelectedNft(nft));

  return (
    <div className="select-nft-grid-container">
      <div className="select-nft-grid">
        {nfts && nfts.length ? (
          <>
            {nfts.map((nft, index) => (
              <SelectNftItem
                name={nft.name}
                imgURL={nft.imgURL}
                onClick={() => onCardClick(nft)}
                key={index}
              />
            ))}
          </>
        ) : (
          <ErrorFallback />
        )}
      </div>
    </div>
  );
};

export default SelectNftGrid;

const ErrorFallback: React.FC = () => (
  <EVGenericText
    text={"No Nfts Here..."}
    size={"large"}
    extraClassNames={"l-mt"}
  />
);
