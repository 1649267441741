import { createAsyncThunk } from '@reduxjs/toolkit';
import OpenseaNftValidatorResult from '../../api/interfaces/OpenseaNftValidatorResult';
import OutputI from '../../../../interfaces/Output';
import { validateOpenseaNFT } from '../../api';
import ReducerSliceNames from '../../../../state/constants/ReducerSliceNames';
import alternativeImageURL from '../../../../constants/alternativeImageURL';

export const sendOpenseaNftValidationAsyncActionName = 'SendOpenseaNftValidation';
export const sendOpenseaNftValidationAsyncAction = createAsyncThunk(
  `${ReducerSliceNames.OPEN_SEA_NFT_VALIDATOR}/${sendOpenseaNftValidationAsyncActionName}`,
  async (openseaUrl: string): Promise<OutputI<OpenseaNftValidatorResult>> => {
    try {
      const response = await validateOpenseaNFT(openseaUrl);
      if (response.err)
        throw new Error(`\n${response.msg}`);
      return { err: false, output: response.output };
    } catch (error) {
      console.error(`\nopensea-nft-validator/state::sendOpenseaNftValidationRequest():: Failed to validateOpenseaNft! | Input: ${openseaUrl} | \n${error}`);
      return { err: true, output: {
        imgURL: alternativeImageURL,
        name: `NFT doesn't exist`,
        valid: false
      } };
    }
  }
);
