import React from "react";
import EVGenericText from "../ev-generic-components/EVGenericText";

export type NftCardFooterProps = {
  title: string;
};

export const NftCardFooter: React.FC<NftCardFooterProps> = ({ title }) => (
  <div className="nft-card-footer s-mt" style={{ width: "100%" }}>
    <EVGenericText
      text={title}
      size={"x-small"}
      type={"p"}
      customCSS={{
        width: "100%",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
      }}
    />
  </div>
);
