import React from "react";
import { useAppDispatch } from "../../state/hooks";
import connectWalletAsyncAction from "../../state/wallet-info-state/actions/connectWalletAsyncAction";
import EVGenericButton from "./EVGenericButton";

type EVGenericConnectWalletButtonProps = {
  disabled?: boolean;
  onConnectWalletClick?: (...args: any) => void;
};

const EVGenericConnectWalletButton: React.FC<
  EVGenericConnectWalletButtonProps
> = ({ onConnectWalletClick, disabled }) => {
  const dispatch = useAppDispatch();

  const onButtonClick = () => {
    if (onConnectWalletClick) {
      onConnectWalletClick();
    } else {
      dispatch(connectWalletAsyncAction());
    }
  };

  return (
    <EVGenericButton
      text="Connect Wallet"
      disabled={disabled}
      cb={onButtonClick}
    />
  );
};

export default EVGenericConnectWalletButton;
