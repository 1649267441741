import { ActionReducerMapBuilder, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import WalletStatusId from '../../constants/WalletStatusId';
import INftData from '../../interfaces/NftData';
import StateStatusNames from '../../interfaces/StateStatusNames';
import ReducerSliceNames from '../constants/ReducerSliceNames';
import { RootState } from '../store';
import connectWalletAsyncAction from './actions/connectWalletAsyncAction';
import mintStakeOneAsyncAction from './actions/mintStakeOneAsyncAction';
import IGetWalletInfoResponse from './interface/GetWalletInfoResponse';

export interface WalletInfoSliceStateI extends IGetWalletInfoResponse {
  status: StateStatusNames;
  provider: ethers.providers.Web3Provider;
  address: string;
}

export const walletInfoInitState: WalletInfoSliceStateI = {
  provider: {} as ethers.providers.Web3Provider,
  walletStatusId: WalletStatusId.NO_QUALIFIED_NFTS,
  status: 'idle',
  qualifiedNfts: [],
  address: '',
  userBalance: 0
};

const extraReducers = (builder: ActionReducerMapBuilder<WalletInfoSliceStateI>) => {
  builder
    // ===== Connect Wallet ===== START
    .addCase(connectWalletAsyncAction.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(connectWalletAsyncAction.fulfilled, (state, action) => {
      if (!action.payload.output) {
        state.status = 'failed';
        state.provider = {} as ethers.providers.Web3Provider;
        state.qualifiedNfts = new Array<INftData>();
        state.walletStatusId = WalletStatusId.NO_QUALIFIED_NFTS;
        state.address = '';
        state.userBalance = 0;
      } else {
        const { address, provider, qualifiedNfts, walletStatusId, userBalance } = action.payload.output;
        state.status = 'idle';
        state.provider = provider;
        state.qualifiedNfts = qualifiedNfts;
        state.walletStatusId = walletStatusId;
        state.address = address;
        state.userBalance = userBalance;           
      }
    })
    .addCase(connectWalletAsyncAction.rejected, (state) => {
      state.status = 'failed';
      state.provider = {} as ethers.providers.Web3Provider;
      state.qualifiedNfts = new Array<INftData>();
      state.walletStatusId = WalletStatusId.NO_QUALIFIED_NFTS;
      state.address = '';
      state.userBalance = 0;
    })
    // ===== Connect Wallet ===== END
    // ===== Mint & Stake One Nft ===== Start
    .addCase(mintStakeOneAsyncAction.pending, (state) => {
      state.status = 'loading';
    })
    .addCase(mintStakeOneAsyncAction.fulfilled, (state, action) => {
      state.status = action.payload.err ? 'failed' : 'idle';
    })
    .addCase(mintStakeOneAsyncAction.rejected, (state) => {
      state.status = 'failed';
    });
  // ===== Mint & Stake One Nft ===== END
};

const walletInfoSlice = createSlice({
  name: ReducerSliceNames.WALLET_INFO,
  initialState: walletInfoInitState,
  reducers: {
    connectWallet: (state, action: PayloadAction<WalletInfoSliceStateI>) => {
      const { provider, qualifiedNfts, walletStatusId, userBalance } = action.payload;
      state.provider = provider;
      state.qualifiedNfts = qualifiedNfts;
      state.walletStatusId = walletStatusId;
      state.userBalance = userBalance;        
    },
    disconnectWallet: (state) => {
      state.provider = {} as ethers.providers.Web3Provider;
      state.qualifiedNfts = new Array<INftData>();
      state.walletStatusId = WalletStatusId.NO_QUALIFIED_NFTS;
      state.address = '';
    },
  },
  extraReducers,
});

export const { connectWallet, disconnectWallet } = walletInfoSlice.actions;
export type WalletInfoSelectors = 'walletStatusId' | 'qualifiedNfts' | 'status' | 'provider' | 'address' | 'userBalance';
export const selectWalletInfo = (selector: WalletInfoSelectors) => (state: RootState) => state.walletInfo[selector];
export default walletInfoSlice.reducer;
