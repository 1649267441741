import React from "react";
import "./EVGenericButton.scss";
import EVGenericText from "./EVGenericText";

export type EVGenericButtonProps = {
  cb?: Function;
  disabled?: boolean;
  isDark?: boolean;
  text?: string;
  ImgURL?: string;
  imgAlt?: string;
  customCSS?: React.CSSProperties;
  extraClassNames?: string;
};

const EVGenericButton: React.FC<EVGenericButtonProps> = ({
  text,
  ImgURL,
  imgAlt,
  disabled,
  isDark,
  cb,
  customCSS,
  extraClassNames,
}) => {
  const onClick = () => {
    cb ? cb() : console.log(`${text} button clicked!`);
  };

  const className = `ev-generic-button-container flex-center  btn-${
    isDark ? "dark" : "light"
  }${disabled ? "-disabled" : ""} ${extraClassNames ? extraClassNames : ""}`;
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={disabled}
      style={customCSS ? { ...customCSS } : {}}
    >
      {ImgURL && (
        <img className="ev-generic-button-img s-mr" src={ImgURL} alt={imgAlt} />
      )}
      {text && (
        <EVGenericText
          text={text}
          size={"small"}
          type={"p"}
          weight={"regular"}
        />
      )}
    </button>
  );
};

export default EVGenericButton;
