import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import INftData from "../../../interfaces/NftData";

import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

/**
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The eth balance of the user
 */
const getEthBalance = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<number>> =>{
    try {
        let balance = await provider.getBalance(walletAddress)
        return {err: false, output: parseFloat(ethers.utils.formatEther(balance))};
    } catch (error) {
        return {err: true, msg: `getEthBalance/index.ts::getEthBalance():: Failed to getEthBalance! || \n${error}`};
    }
}

export default getEthBalance;