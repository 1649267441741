import { FAQTextProps } from "../features/opensea-nft-validator/components/faq/FAQParagraph";

export const faqs: FAQTextProps[] = [
  {
    question: "What is Sacrifice2Mint?",
    answer:
      "A revolutionary new minting format that enables you to\nexchange a qualifying NFT for an Eternal Viking NFT!",
  },
  {
    question: "How does my NFT qualify for Sacrifice2Mint?",
    answer: "The Old Gods use a range of smart parameters that\nwe cannot reveal."
  },
  {
    question: "How many sacrifices are allowed per wallet?",
    answer:
      "If you Sacrifice&Stake your first qualified NFT, you will be able to\nsacrifice another NFT within the same wallet. (max 2 per wallet)",
  },
  {
    question: "What happens to the NFTs I sacrifice?",
    answer:
      "All sacrificed NFTs are moved to the EV Sacrifice wallet, where they\nshall be used as dynamic resources to support project roadmap.",
  },
  {
    question: "Why don’t you do a regular mint?",
    answer:
      "We believe that to hold an Eternal Viking NFT, you must earn it -\nand earning is not necessarily just about buying.",
  },
  {
    question: "What about Gas fees?",
    answer: "You will need to pay Gas fees only once for completing the swap\naction of Sacrifice2Mint.",
  },
];
