import React from "react";
import "./OpenSeaNftValidatorResultRight.scss";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppSelector, useAppDispatch } from "../../../../state/hooks";
import OpenseaNftValidatorResult from "../../api/interfaces/OpenseaNftValidatorResult";
import { OpenSeaNftValidatorStageId } from "../../constants/OpenSeaNftValidatorStageId";
import {
  selectOpenSeaNftValidator,
  setValidationResult,
  validationResultInitState,
  setInput,
  setStageId,
} from "../../state";
import { setCurrentPlayedVideo } from "../../../main-design-system/state";
import ReactGA from "react-ga";
import {
  resultRightValidTitle,
  resultRightInValidTitle,
  resultRightOr,
  resultRightRetryButton,
} from "../../constants/OpenSeaNftValidatorTexts";
import { premintURL } from "../../constants/premintURL";

const OpenSeaNftValidatorResultRight: React.FC = () => {
  const { valid } = useAppSelector(
    selectOpenSeaNftValidator("validationResult")
  ) as OpenseaNftValidatorResult;

  const dispatch = useAppDispatch();

  const reset = () => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: "click-on-retry",
        category: "button",
      });
    }
    dispatch(setValidationResult(validationResultInitState));
    dispatch(setInput(""));
    dispatch(setStageId(OpenSeaNftValidatorStageId.FORM));
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedBlueIdle",
        onVideoEnded: undefined,
      })
    );
  };

  const onClick = () => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: "click-on-register",
        category: "button",
      });
    }
    dispatch(setStageId(OpenSeaNftValidatorStageId.CONNECTED));
  };

  const text = valid ? resultRightValidTitle : resultRightInValidTitle;

  return (
    <div className="opensea-nft-validator-result-right-container flex column space-around align-center">
      <EVGenericText
        text={text}
        type={"h2"}
        size={"custom"}
        extraClassNames={"m-mb"}
        customCSS={{
          fontSize: "1.9rem",
        }}
      />
      {valid ? (
        <>
          <a href={premintURL} target={"_blank"} rel="noreferrer">
            <EVGenericButton text="Register" cb={onClick} />
          </a>
          <EVGenericText text={resultRightOr} extraClassNames={"s-m"} />
          <EVGenericText
            text={resultRightRetryButton}
            size={"small"}
            extraClassNames={"clickable-text"}
            onClick={reset}
          />
        </>
      ) : (
        <EVGenericButton text={resultRightRetryButton} cb={reset} />
      )}
    </div>
  );
};

export default OpenSeaNftValidatorResultRight;
