import React from "react";
import EVGenericText from "../../components/ev-generic-components/EVGenericText";
import SacrificeCountdownSection from "../sacrifice-countdown/SacrificeCountdownSection";
import SacrificeCountdownSectionPlaceholder from "../sacrifice-countdown/SacrificeCountdownSectionPlaceholder";
import { subtitleText, titleText } from "./constants/welcomeSectionTexts";

const WelcomeSectionTitle: React.FC = () => (
  <div className="welcome-section-header-container">
    <div className="welcome-section-header-title flex column l-mb">
      <EVGenericText text={subtitleText} weight={"regular"} type={"h2"} />
      <EVGenericText
        text={titleText}
        size={"custom"}
        type={"h1"}
        customCSS={{
          fontFamily: `"Vikings", sans-serif`,
          fontSize: "4.4rem",
          fontWeight: 500,
        }}
        extraClassNames={"s-mt"}
      />
    </div>

    <SacrificeCountdownSection />
    {/* <SacrificeCountdownSectionPlaceholder /> */}
  </div>
);
export default WelcomeSectionTitle;
