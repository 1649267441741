import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { useAppDispatch } from "../../state/hooks";
import { setStageId } from "../../features/opensea-nft-validator/state";
import { OpenSeaNftValidatorStageId } from "../../features/opensea-nft-validator/constants/OpenSeaNftValidatorStageId";
import { setForegroundStageId } from "../../features/main-design-system/state";
import ForegroundStageId from "../../constants/ForegroundStageId";
import { useAnimation } from "framer-motion";
import { setGuardControl } from "../../features/bribe/state";

const Initializations: React.FC = () => {
  if (process.env.REACT_APP_GA_ID) {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview(window.location.pathname);
  }

  const dispatch = useAppDispatch();

  const guardControl = useAnimation();

  useEffect(() => {
    dispatch(setGuardControl(guardControl));
    dispatch(setStageId(OpenSeaNftValidatorStageId.FORM));
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  }, []);

  return <></>;
};

export default Initializations;
