import React, { useCallback, useEffect, useState } from "react";
import "./SacrificeSection.scss";
import BackButton from "../../components/back-button/BackButton";
import SacrificeSectionSwapDisplay from "./components/swap-display/SacrificeSectionSwapDisplay";
import SacrificeSectionContent from "./components/content/SacrificeSectionContent";
import SacrificePopup from "./components/sacrifice-popup/SacrificePopup";
import { BGVideoNames } from "../../assets/videos";
import { useAppSelector, useAppDispatch } from "../../state/hooks";
import {
  selectMainLayoutSystem,
  setCurrentPlayedVideo,
  setForegroundStageId,
} from "../main-design-system/state";
import waitForSeconds from "../../utils/waitForSeconds";
import ForegroundStageId from "../../constants/ForegroundStageId";
import { clearSelectedNft } from "../select-nft/state";

export enum MintState {
  BEFORE = 1,
  SACRIFICE_POPUP,
  SACRIFICE_STAKE_POPUP,
  SACRIFICE_VIDEO,
  AFTER,
}

const SacrificeSection: React.FC = () => {
  const [mintState, setMintState] = useState<MintState>(MintState.BEFORE);

  const currentVideo = useAppSelector(
    selectMainLayoutSystem("currentPlayedVideo")
  ) as BGVideoNames;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentVideo !== "TempleIdle")
      dispatch(
        setCurrentPlayedVideo({
          videoName: "TempleIdle",
          onVideoEnded: undefined,
        })
      );
  }, []);

  const runSacrificeAnimations = () => {
    setMintState(MintState.SACRIFICE_VIDEO);
    dispatch(
      setCurrentPlayedVideo({
        videoName: "TempleIdle",
        onVideoEnded: async () => {
          dispatch(
            setCurrentPlayedVideo({
              videoName: "TempleSacrifice",
              onVideoEnded: () => {
                dispatch(
                  setCurrentPlayedVideo({
                    videoName: "TempleIdle",
                    onVideoEnded: undefined,
                  })
                );
              },
            })
          );
          await waitForSeconds(4);
          setMintState(MintState.AFTER);
        },
      })
    );
  };

  const showContent = useCallback(
    () => mintState === MintState.AFTER || mintState === MintState.BEFORE,
    [mintState]
  );

  const onBackClick = () => {
    dispatch(clearSelectedNft());
    dispatch(setForegroundStageId(ForegroundStageId.HALLWAY));
  };

  return (
    <>
      {mintState === MintState.BEFORE && (
        <BackButton onBackClick={onBackClick} />
      )}
      <div className="sacrifice-section-container flex column justify-start align-center">
        {(mintState === MintState.SACRIFICE_POPUP ||
          mintState === MintState.SACRIFICE_STAKE_POPUP) && (
          <SacrificePopup
            mintState={mintState}
            runSacrificeAnimations={runSacrificeAnimations}
          />
        )}
        <SacrificeSectionSwapDisplay mintState={mintState} />
        {showContent() && (
          <SacrificeSectionContent
            mintState={mintState}
            setMintState={setMintState}
          />
        )}
      </div>
    </>
  );
};

export default SacrificeSection;
