import React, { useEffect, useState } from "react";
import CountdownTimer from "../../components/conutdown-display/CountdownTimer";
import EVGenericText from "../../components/ev-generic-components/EVGenericText";
import getCurrentCountdownDisplayIndex from "../../utils/getCurrentCountdown";
import CountdownDisplayIndex from "./constants/CountdownDisplayIndex";
import ICountdownContent from "./interfaces/ICountdownContent";
import countdownDisplayContent from "./constants/countdownDisplayContent";

const SacrificeCountdownSection: React.FC = () => {
  const [{ title, targetDate }, setCountdownContent] =
    useState<ICountdownContent>(
      countdownDisplayContent.get(
        CountdownDisplayIndex.WHITELIST_MINT
      ) as ICountdownContent
    );

  useEffect(
    () =>
      setCountdownContent(
        countdownDisplayContent.get(
          getCurrentCountdownDisplayIndex()
        ) as ICountdownContent
      ),
    []
  );

  return (
    <div className="sacrifice-countdown-container flex column">
      <EVGenericText
        text={title}
        size={"medium"}
        type={"h3"}
        extraClassNames={"s-mb"}
      />
      {!!targetDate && <CountdownTimer targetDate={targetDate} />}
    </div>
  );
};

export default SacrificeCountdownSection;
