enum ForegroundStageId {
  WELCOME_SECTION = 1,
  OPENSEA_NFT_VALIDATOR,
  HALLWAY,
  SHRINE,
  BRIBE,
  // For loading or not displaying anything, use these
  NULL = 98,
  LOADING = 99,
}

export default ForegroundStageId;