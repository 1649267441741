import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { titleTxt } from "./constants/HowItWorksTexts";

const WelcomeSectionHowItWorksHeader: React.FC = () => (
  <div className="welcome-section-how-it-works-header flex align-center justify-start w-all m-mb">
    <EVGenericText size={"small"} weight={"bold"} text={titleTxt} />
  </div>
);

export default WelcomeSectionHowItWorksHeader;
