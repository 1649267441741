/**
 * @KASH 
 * 
 * @TODO - This is where the magic happens, in each of these functions you will need to sign the transactions.
 * In each of these transaction functions, you have the provider signed with the user's wallet passed as a parameter.
 * Signer (user's wallet) can be accessed with provider.getSigner();
 * 
 * @provider
 * @type {ethers.providers.Web3Provider}
 */


import { ethers } from "ethers";
import OutputI from "../../../interfaces/Output";
import waitForSeconds from "../../../utils/waitForSeconds";

import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

export const mintStakeOneNft = async (provider: ethers.providers.Web3Provider): Promise<OutputI<{}>> => {
    try {
        const evSacrificeContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);            
        const tx = await evSacrificeContract.connect(provider.getSigner()).sacrificeAltNew({ value: ethers.utils.parseEther(process.env.REACT_APP_ETH_MINT_AMOUNT || "0.059") });
        await tx.wait();
        return {err: false, output: {}}   
    } catch (error) {      
        return {err: true, msg: `bribe-mint-flow/index.ts::mintOneNft():: failed while sending mint transaction! | \n${error}`}
    }
}
