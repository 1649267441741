import React from "react";
import { AnimatePresence } from "framer-motion";
import { useAppSelector } from "../../../../state/hooks";
import { selectBribe } from "../../state";
import AfterBribeTextBubble from "./components/AfterBribeTextBubble";
import BeforeBribeTextBubble from "./components/BeforeBribeTextBubble";
import CurrentBribeTextBubble from "./components/CurrentBribeTextBubble";
import IntroBribeTextBubble from "./components/IntroBribeTextBubble";
import { TextBubbleIndex } from "./constants/TextBubbleIndex";

const TextBubble: React.FC = () => {
  const textBubbleIndex = useAppSelector(
    selectBribe("textBubbleIndex")
  ) as TextBubbleIndex;

  const getCurrentTextBubble = (textBubbleIndex: TextBubbleIndex) => {
    switch (textBubbleIndex) {
      case TextBubbleIndex.INTRO:
        return <IntroBribeTextBubble />;
      case TextBubbleIndex.BEFORE_MINT:
        return <BeforeBribeTextBubble />;
      case TextBubbleIndex.CURRENT_MINT:
        return <CurrentBribeTextBubble />;
      case TextBubbleIndex.AFTER_MINT:
        return <AfterBribeTextBubble />;
      default:
        return <></>;
    }
  };

  return (
    <AnimatePresence>
      {textBubbleIndex !== TextBubbleIndex.NULL &&
        getCurrentTextBubble(textBubbleIndex)}
    </AnimatePresence>
  );
};

export default TextBubble;
