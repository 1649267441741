import React from "react";
import ForegroundStageId from "../../constants/ForegroundStageId";
import { useAppSelector } from "../../state/hooks";
import { selectMainLayoutSystem } from "../main-design-system/state";
import { OpenSeaNftValidatorStageId } from "../opensea-nft-validator/constants/OpenSeaNftValidatorStageId";
import OpenSeaNftValidator from "../opensea-nft-validator/OpenSeaNftValidator";
import { selectOpenSeaNftValidator } from "../opensea-nft-validator/state";
import WelcomeSectionTitle from "./WelcomeSectionTitle";
import "./WelcomeSection.scss";
import WelcomeSectionMain from "./WelcomeSectionMain";
import { selectWalletInfo } from "../../state/wallet-info-state";
import WalletStatusId from "../../constants/WalletStatusId";
import { FullscreenGradFrame } from "../../components/fullscreen-grad-frame/FullscreenGradFrame";

const WelcomeSection: React.FC = () => {
  const openseaNftValidatorStageId = useAppSelector(
    selectOpenSeaNftValidator("stageId")
  ) as OpenSeaNftValidatorStageId;
  const mainLayoutStageId = useAppSelector(
    selectMainLayoutSystem("foregroundStageId")
  ) as ForegroundStageId;
  const walletStatusId = useAppSelector(
    selectWalletInfo("walletStatusId")
  ) as WalletStatusId;

  const showWelcomeSectionTitle =
    (mainLayoutStageId === ForegroundStageId.WELCOME_SECTION &&
      walletStatusId === WalletStatusId.NULL) ||
    (mainLayoutStageId === ForegroundStageId.OPENSEA_NFT_VALIDATOR &&
      openseaNftValidatorStageId === OpenSeaNftValidatorStageId.FORM);

  return (
    <FullscreenGradFrame>
      <section className="welcome-section-container flex column-reverse space-between">
        {mainLayoutStageId === ForegroundStageId.OPENSEA_NFT_VALIDATOR && (
          <OpenSeaNftValidator />
        )}
        {mainLayoutStageId === ForegroundStageId.WELCOME_SECTION && (
          <WelcomeSectionMain />
        )}
        {showWelcomeSectionTitle && <WelcomeSectionTitle />}
      </section>
    </FullscreenGradFrame>
  );
};

export default WelcomeSection;
