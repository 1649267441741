import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import {
  connectedLeftSubTitleTxt,
  connectedLeftTitleTxt,
} from "../../constants/OpenSeaNftValidatorTexts";

const OpenSeaNftValidatorConnectedLeft: React.FC = () => (
  <div className="opensea-nft-validator-connected-left flex column align-start">
    <EVGenericText
      text={connectedLeftTitleTxt}
      size={"large"}
      type={"h2"}
      weight={"bold"}
      extraClassNames={"xs-mb"}
    />
    <EVGenericText
      text={connectedLeftSubTitleTxt}
      extraClassNames={"s-mb"}
      size={"medium"}
      customCSS={
        {
          // lineHeight: "2.3rem",
        }
      }
    />
  </div>
);

export default OpenSeaNftValidatorConnectedLeft;
