import React from "react";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppDispatch } from "../../../../state/hooks";
import { disconnectWallet } from "../../../../state/wallet-info-state";
import openPanelInNewTab from "../../../../utils/openPanelInNewTab";
import { resetBribe } from "../../../bribe/state";
import { setCurrentPlayedVideo } from "../../../main-design-system/state";
import {
  ctaOneText,
  ctaSeparator,
  ctaTwoText,
  titleText,
} from "./constants/MaximumTwoNftsTexts";
import "./InvalidWalletErrorsMaximumTwoNfts.scss";

const InvalidWalletErrorsMaximumTwoNfts: React.FC = () => {
  return (
    <div className="invalid-wallet-errors-maximum-two-nfts-container flex column-reverse space-between align-center bg-grad-ltr round-corners-narrow m-pad">
      <Content />
      <RenderHeader />
    </div>
  );
};

const RenderHeader = () => (
  <>
    <EVGenericText
      text={titleText}
      type="h2"
      size={"custom"}
      extraClassNames={"m-mb"}
      customCSS={{
        fontSize: "1.9rem",
      }}
    />
  </>
);

const Content: React.FC = () => {
  const dispatch = useAppDispatch();

  const onBackButtonClick = () => {
    dispatch(resetBribe());
    dispatch(disconnectWallet());
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedBlueIdle",
      })
    );
  };
  const onGoToPanelClick = () => {
    openPanelInNewTab();
  };
  return (
    <div className="invalid-wallet-errors-maximum-two-nfts-ctas-container flex column space-between align-center">
      <EVGenericButton
        text={ctaOneText}
        cb={onBackButtonClick}
        customCSS={{ width: "100%" }}
      />
      <EVGenericText text={ctaSeparator} extraClassNames={"s-m"} />
      <EVGenericText
        text={ctaTwoText}
        extraClassNames={"clickable-text"}
        onClick={onGoToPanelClick}
      />
    </div>
  );
};

export default InvalidWalletErrorsMaximumTwoNfts;
