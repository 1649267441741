import React from 'react';
import EVGenericText from '../../../../components/ev-generic-components/EVGenericText';
import SeparateLine from '../../../../components/separate-line/SeparateLine';
import ForegroundStageId from '../../../../constants/ForegroundStageId';
import { useAppDispatch } from '../../../../state/hooks';
import openNftsListNewTab from '../../../../utils/openNftsListNewTab';
import { setForegroundStageId } from '../../../main-design-system/state';
import {
  stepOneMainTxt,
  stepOneSubClickableTxt,
  stepOneSubTxt,
  stepThreeTxt,
  stepTwoTxt,
} from './constants/HowItWorksTexts';
import { HowItWorksParagraph } from './HowItWorksParagraph';

const WelcomeSectionHowItWorksContent: React.FC = () => {
  const dispatch = useAppDispatch();

  const moveToOpenseaNFTValidator = () => {
    dispatch(setForegroundStageId(ForegroundStageId.OPENSEA_NFT_VALIDATOR));
  };

  return (
    <div className='welcome-section-how-it-works-content flex column align-start space-between'>
      <HowItWorksParagraph stepIndex={1}>
        <EVGenericText
          size={'small'}
          text={stepOneMainTxt}
          customCSS={{
            lineHeight: '2.6rem',
          }}
        />
        <div className='welcome-section-how-it-works-check-your-nfts flex justify-start align-center w-all'>
          <EVGenericText size={'small'} text={stepOneSubTxt} />
          {/*<EVGenericText
            size={'small'}
            text={stepOneSubClickableTxt}
            extraClassNames={'clickable-text xs-ml '}
            onClick={moveToOpenseaNFTValidator}
        />*/}
          {/*<EVGenericText size={'small'} extraClassNames={'xs-ml'} text={'or'} />*/}
          <EVGenericText
            size={'small'}
            text={'See the list'}
            extraClassNames={'clickable-text xs-ml'}
            onClick={() => openNftsListNewTab()}
          />
        </div>
      </HowItWorksParagraph>
      <SeparateLine legnthCSSDefinition={'90%'} />
      <HowItWorksParagraph stepIndex={2}>
        <EVGenericText text={stepTwoTxt} />
      </HowItWorksParagraph>
      <SeparateLine legnthCSSDefinition={'90%'} />
      <HowItWorksParagraph stepIndex={3}>
        <EVGenericText text={stepThreeTxt} />
      </HowItWorksParagraph>
    </div>
  );
};
export default WelcomeSectionHowItWorksContent;
