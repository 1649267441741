import OutputI from "../../../interfaces/Output";
import openWeb3Modal from "./web3modal";
import { ethers } from "ethers";

/**
 * @dev This method will be used to connect the user's wallet to a provider for handling txns.
 * @return {string} The user's wallet address for the web app's use.
 */
const connectWallet = async (): Promise<OutputI<ethers.providers.Web3Provider>> => {
    try {
        const result = await openWeb3Modal()
        if(result.err) throw new Error(result.msg);
        if(!result.output) throw new Error("provider is undefined");
        return {err:false, output: result.output};
    } catch (error) {
        return {err:true, msg: `connect-wallet/index.ts::connectWallet():: Failed to connect to wallet! | \n${error}`};
    }
}

export default connectWallet;


