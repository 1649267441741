export const connectedLeftTitleTxt = "The Gods approve your NFT"
export const connectedLeftSubTitleTxt = "Reserve your spot at the Sacrifice2Mint Ceremony"
export const connectedRightTitleTxt = "See you soon..."
export const faqTitleTxt = "FAQ";
export const formTitleTxt = "Does your NFT qualify for sacrifice?"
export const formCheckButtonTxt = "Check"
export const formInputPlaceholderTxt = "Enter NFT item Opensea URL (Eth only)"
export const formInputInvalidTxt = "Enter NFT item Opensea URL (Eth only)"
export const resultLeftValidTitle = "Your NFT qualifies!"
export const resultLeftValidSubTitle = "The Old Gods shall invite you to the Sacrifice,\ndate announced soon, be ready..."
export const resultLeftInValidTitle = "Sorry, your NFT doesn't qualify"
export const resultLeftInValidSubTitle = "The Gods will not bless you with an Eternal Viking NFT"
export const resultRightValidTitle = "Secure your Sacrifice Spot"
export const resultRightInValidTitle = "No luck?"
export const resultRightOr = "or"
export const resultRightRetryButton = "Try another one"