import React from 'react';
import WalletStatusId from '../../constants/WalletStatusId';
import { useAppSelector } from '../../state/hooks';
import { selectWalletInfo } from '../../state/wallet-info-state';
import BlessedDone from './components/blessed-done/BleasedDone';
import WelcomeSectionEnterTemple from './components/enter-temple/WelcomeSectionEnterTemple';
import WelcomeSectionHowItWorks from './components/how-it-works/WelcomeSectionHowItWorks';
import InvalidWalletErrorsMaximumTwoNfts from './components/maximum-two-nfts/InvalidWalletErrorsMaximumTwoNfts';
import InvalidWalletErrorsNoQualifiedNfts from './components/no-qualified-nfts/InvalidWalletErrorsNoQualifiedNfts';
import NoRegistered from './components/no-registered/NoRegistered';
import InvalidWalletErrorsNotWhitelisted from './components/not-whitelisted/InvalidWalletErrorsNotWhitelisted';
import WelcomeSectionConnectWallet from './components/welcome-section-connect-wallet/WelcomeSectionConnectWallet';

const WelcomeSectionMain: React.FC = () => (
  <div className='welcome-section-main flex row space-between align-end'>
    <RenderComponent />
  </div>
);

const RenderComponent: React.FC = () => {
  const walletStatusId = useAppSelector(selectWalletInfo('walletStatusId')) as WalletStatusId;
  

  switch (walletStatusId) {
    case WalletStatusId.NULL:
      return (
        <>
          <WelcomeSectionHowItWorks />
          <WelcomeSectionConnectWallet />
        </>
      );
    case WalletStatusId.MINT:
      return <WelcomeSectionEnterTemple />;
    case WalletStatusId.NOT_WHITELISTED:
      return <InvalidWalletErrorsNotWhitelisted />;
    case WalletStatusId.EXCEEDED_AMOUNT:
      return <InvalidWalletErrorsMaximumTwoNfts />;
    case WalletStatusId.NOT_REGISTERED:
      return <NoRegistered />;
    case WalletStatusId.BLESSED_DONE:
      return <BlessedDone />;
    case WalletStatusId.NO_QUALIFIED_NFTS:
    default:
      return <InvalidWalletErrorsNoQualifiedNfts />;
  }
};

export default WelcomeSectionMain;
