import React from "react";
import "./OpenseaNftValidatorConnectedRight.scss";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import ShareTweetButton from "../../../../components/share-tweet-button/ShareTweetButton";
import { useAppSelector } from "../../../../state/hooks";
import OpenseaNftValidatorResult from "../../api/interfaces/OpenseaNftValidatorResult";
import { selectOpenSeaNftValidator } from "../../state";
import { connectedRightTitleTxt } from "../../constants/OpenSeaNftValidatorTexts";

const OpenSeaNftValidatorConnectedRight: React.FC = () => {
  const { name } = useAppSelector(
    selectOpenSeaNftValidator("validationResult")
  ) as OpenseaNftValidatorResult;

  return (
    <div className="opensea-nft-validator-connected-right flex column space-around align-center">
      <EVGenericText
        text={connectedRightTitleTxt}
        size={"medium"}
        type={"h3"}
        extraClassNames={"s-mb"}
      />
      <ShareTweetButton nftName={name} />
    </div>
  );
};

export default OpenSeaNftValidatorConnectedRight;
