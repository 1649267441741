import { ActionReducerMapBuilder, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BGVideoNames } from "../../../assets/videos";
import ForegroundStageId from "../../../constants/ForegroundStageId";
import StateStatusNames from "../../../interfaces/StateStatusNames";
import ReducerSliceNames from "../../../state/constants/ReducerSliceNames";
import { RootState } from "../../../state/store";

export interface BackgroundVideoI {
  videoName: BGVideoNames
  onVideoEnded?: ()=>void;
}

export const backgroundVideoInitState: BackgroundVideoI = {
  videoName: 'DoorClosedRedIdle',
  onVideoEnded: undefined,
}

export interface MainLayoutSystemStateI {  
  status: StateStatusNames
  foregroundStageId: ForegroundStageId
  currentPlayedVideo: BackgroundVideoI
  onForegroundFadeAnimationEnd?: ()=>void;
}

export const mainLayoutSystemInitState: MainLayoutSystemStateI = {
  status: 'idle',
  foregroundStageId: ForegroundStageId.NULL,
  currentPlayedVideo: backgroundVideoInitState,
  onForegroundFadeAnimationEnd: undefined
};

const extraReducers = (builder: ActionReducerMapBuilder<MainLayoutSystemStateI>)=>{}

const mainLayoutSystem = createSlice({
  name: ReducerSliceNames.MAIN_LAYOUT_SYSTEM,
  initialState: mainLayoutSystemInitState,
  reducers: {
    setForegroundStageId: (state, payload: PayloadAction<ForegroundStageId>)=>{
      state.foregroundStageId = payload.payload;
    },
    setCurrentPlayedVideo: (state, payload: PayloadAction<BackgroundVideoI>)=>{
      state.currentPlayedVideo = payload.payload;
    },
    setOnForegroundFadeAnimationEnd: (state, payload: PayloadAction<()=>void>)=>{
      state.onForegroundFadeAnimationEnd = payload.payload;
    }
  },
  extraReducers
});

export const { setForegroundStageId, setCurrentPlayedVideo, setOnForegroundFadeAnimationEnd } = mainLayoutSystem.actions;
export type MainLayoutSystemSelectors = 'status' | 'foregroundStageId' | 'currentPlayedVideo' | 'onForegroundFadeAnimationEnd';
export const selectMainLayoutSystem = (selector: MainLayoutSystemSelectors)=>(state: RootState) => state.mainLayoutSystem[selector];
export default mainLayoutSystem.reducer;