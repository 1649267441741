import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

/**
 * Returns the WL tokens in a users wallet
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The id of the last EV NFT id the user has minted.
 */
const getStakedEvAmount = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<number>> =>{
    try {     
        const stakingContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsStaking);     
        const userStakedNFTs = await stakingContract.walletEVStakeCount(walletAddress);
        const output = userStakedNFTs.toNumber();
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `get-staked-ev-amount/index.ts::getStakedEvAmount():: Failed to get staked vikings of user! || \n${error}`};
    }
}

export default getStakedEvAmount;