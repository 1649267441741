import React from "react";
import EVGenericText from "../ev-generic-components/EVGenericText";

export type DateTimeDisplayType = {
  value: number;
  valueTypeName: "days" | "hours" | "minutes" | "seconds";
  highlight?: boolean;
};
const DateTimeDisplay: React.FC<DateTimeDisplayType> = ({
  value,
  valueTypeName,
  highlight = false,
}) => {
  return (
    <div className={`countdown ${highlight ? " danger" : ""}`}>
      <EVGenericText
        text={value.toString()}
        customCSS={{
          fontWeight: "200",
          fontSize: "3rem",
          letterSpacing: "0.275rem",
        }}
      />
      {/* <span>{valueTypeName.toLocaleUpperCase()}</span> */}
    </div>
  );
};
export default DateTimeDisplay;
