import React from "react";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppDispatch } from "../../../../state/hooks";
import { disconnectWallet } from "../../../../state/wallet-info-state";
import { resetBribe } from "../../../bribe/state";
import { setCurrentPlayedVideo } from "../../../main-design-system/state";
import {
  backButtonText,
  contentText,
  titleText,
} from "./constants/NotWhitelistedTexts";
import "./InvalidWalletErrorsNotWhitelisted.scss";

const InvalidWalletErrorsNotWhitelisted: React.FC = () => {
  return (
    <div className="invalid-wallet-errors-not-whitelisted-container flex column space-between align-start bg-grad-ltr m-pad round-corners-narrow">
      <Header />
      <Content />
      <Footer />
    </div>
  );
};

export default InvalidWalletErrorsNotWhitelisted;

const Header: React.FC = () => (
  <>
    <EVGenericText
      type="h2"
      size={"large"}
      weight={"bold"}
      text={titleText}
      extraClassNames={"s-mb"}
    />
  </>
);

const Content: React.FC = () => (
  <>
    <EVGenericText text={contentText} size={"x-small"} />
  </>
);

const Footer: React.FC = () => {
  const dispatch = useAppDispatch();

  const onBackButtonClick = () => {
    dispatch(resetBribe());
    dispatch(disconnectWallet());
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedBlueIdle",
      })
    );
  };

  return (
    <>
      <EVGenericButton
        text={backButtonText}
        extraClassNames={"s-mt"}
        customCSS={{ width: "75%" }}
        cb={onBackButtonClick}
      />
    </>
  );
};
