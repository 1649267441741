import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import getContractInstance from "../utils/getContractInstance";
import ContractIdentifiers from "../../../constants/contractIdentifiers";

/**
 * Returns the WL tokens in a users wallet
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The id of the last EV NFT id the user has minted.
 */
const getMintedEvAmount = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<number>> =>{
    try {
        const sacrificeContract = await getContractInstance(provider, ContractIdentifiers.app.EternalVikingsSacrificer);        
        const userMintedNFTs = await sacrificeContract.walletCount(walletAddress);
        const output = userMintedNFTs.toNumber();
        return {err: false, output};
    } catch (error) {
        return {err: true, msg: `get-minted-ev-amount/index.ts::getMintedEvAmount():: Failed to get minted vikings of user! || \n${error}`};
    }
}

export default getMintedEvAmount;