import React from "react";
import "./FullscreenGradFrame.scss";

type FullscreenGradFrameProps = {
  children: JSX.Element | JSX.Element[] | undefined;
};
export const FullscreenGradFrame: React.FC<FullscreenGradFrameProps> = ({
  children,
}) => (
  <>
    <div className="fullscreen-grad-frame-top bg-grad-down" />
    {children && children}
    <div className="fullscreen-grad-frame-bottom bg-grad-up" />
  </>
);
