import React, { useState } from "react";
import { TwitterIcon } from "../../assets/images";
import generateSharePostUrl from "../../utils/generateSharePostText";
import EVGenericButton from "../ev-generic-components/EVGenericButton";
import ReactGA from "react-ga";

export type ShareTweetButtonProps = {
  nftName: string;
  isDark?: boolean;
};

const ShareTweetButton: React.FC<ShareTweetButtonProps> = ({
  nftName,
  isDark,
}) => {
  const [shared, setShared] = useState<boolean>(false);

  const onClick = () => {
    setShared(true);
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: "click-on-share",
        category: "button",
      });
    }
    window.open(generateSharePostUrl(nftName), "_blank", "noopener,noreferrer");
    return null;
  };

  return (
    <EVGenericButton
      ImgURL={TwitterIcon}
      imgAlt={"twitter-icon"}
      text={`Share`}
      disabled={shared}
      cb={onClick}
    />
  );
};

export default ShareTweetButton;
