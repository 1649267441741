import React from "react";
import { parseImgURL } from "./utils/parseImgURL";

export type NftCardHeaderImgProps = {
  imgURL: string;
  // lazy?: boolean;
};

const NftCardHeaderImg: React.FC<NftCardHeaderImgProps> = ({ imgURL }) => {
  // const [imgURLState, setImgURLState] = useState("");
  // useEffect(() => {
  //   (async () => {
  //     await new Promise((res, rej) =>
  //       setTimeout(() => {
  //         res(setImgURLState(imgURL));
  //       }, (Math.random() + 1) * 2 * 1000)
  //     );
  //   })();
  // });

  return (
    <img
      className="nft-card-header-img round-corners-wide"
      loading="lazy"
      src={parseImgURL(imgURL)}
      alt="nft"
      style={{
        height: "14rem",
        width: "14rem",
      }}
    />
  );
};

export default NftCardHeaderImg;
