import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import ReducerSliceNames from '../constants/ReducerSliceNames';
import { RootState } from '../store';

export interface GeneralInfoStateI {
  isPublicMintPeriod: boolean;
  audioMuted: boolean;
  firstPlay: boolean;
}

export const generalInfoInitState: GeneralInfoStateI = {
    isPublicMintPeriod: Date.now() > parseInt(process.env.REACT_APP_PUBLIC_MINT_DATE),
    audioMuted: true,
    firstPlay: false
};

const generalInfoSlice = createSlice({
  name: ReducerSliceNames.GENERAL_INFO,
  initialState: generalInfoInitState,
  reducers: {
    toggleAudioMuted: (state, action: PayloadAction<boolean>)=>{
      state.audioMuted = action.payload;
    },
    toggleFirstPlay: (state, action: PayloadAction<boolean>)=>{
      state.firstPlay = action.payload;
    },
  },
});

export const { toggleAudioMuted, toggleFirstPlay } = generalInfoSlice.actions;
export type GeneralInfoSelectors = 'isPublicMintPeriod' | 'audioMuted' | 'firstPlay';
export const selectGeneralInfo = (selector: GeneralInfoSelectors)=>(state: RootState) => state.generalInfo[selector];
export default generalInfoSlice.reducer;
