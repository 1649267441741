import { Variants } from 'framer-motion';

export enum GuardVariantNames {
  RESET = 'reset',
  SNEAK_HIDDEN = 'sneakHidden',
  SNEAK_IN = 'sneakIn',
  IDLE_HOVER = 'idleHover',
  FLY_TO_MID = 'flyToMid',
  LEAVE = 'leave',
}

const sneakGuardSize = '40rem';
const midGuardSize = '50rem';

export const GuardVariants = {
  [GuardVariantNames.RESET]: {
    right: '-42rem',
    top: '45%',
    translateY: '-45%',
    rotate: '0',
    width: sneakGuardSize,
    height: `calc(${sneakGuardSize} * 1.1)`,
  },
  [GuardVariantNames.SNEAK_HIDDEN]: {
    right: '-42rem',
    top: '45%',
    translateY: '-45%',
    rotate: '0',
    width: sneakGuardSize,
    height: `calc(${sneakGuardSize} * 1.1)`,
    transition: {
      duration: 1.5,
    },
  },
  [GuardVariantNames.SNEAK_IN]: {
    right: '-24rem',
    top: '45%',
    translateY: '-45%',
    rotate: '-10deg',
    transition: {
      delay: 2.5,
      duration: 1,
    },
  },
  [GuardVariantNames.IDLE_HOVER]: {
    y: [0, -20, 0],
    transition: {
      duration: 3,
      repeat: Infinity,
    },
  },
  [GuardVariantNames.FLY_TO_MID]: {
    top: '50%',
    left: '30%',
    translateY: '-50%',
    translateX: '-30%',
    rotate: '0deg',
    width: midGuardSize,
    height: `calc(${midGuardSize} * 1.1)`,
    transition: {
      delay: 1,
      duration: 1.5,
    },
  },
  [GuardVariantNames.LEAVE]: {
    top: '50%',
    translateY: '-50%',
    left: '-42rem',
    rotate: '-10deg',
    width: sneakGuardSize,
    height: `calc(${sneakGuardSize} * 1.1)`,
    transition: {
      delay: 1,
      duration: 2,
    },
  },
} as Variants;
