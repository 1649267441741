import { Variant, Variants } from "framer-motion";

const floatItemIdleAnimation = (): Variant => ({
  y: [-20, 0, -20],
  transition: {
    duration: Math.floor(Math.random() * 3) + 3,
    repeat: Infinity,
  },
});
export const evFloatItemVariants: Variants = {
  initial: {
    scale: 0.6,
    opacity: 0.5,
  },
  idle: { ...floatItemIdleAnimation(), scale: 0.6, opacity: 0.5 },
  sacrifice: {
    bottom: 0,
    left: "50%",
    translateX: "-50%",
    scale: 1.3,
    opacity: 1,
    transition: {
      duration: 3,
    },
  },
  frontIdle: {
    y: [-20, 0, -20],
    bottom: 0,
    left: "50%",
    translateX: "-50%",
    scale: 1.3,
    opacity: 1,
    transition: {
      duration: Math.floor(Math.random() * 3) + 3,
      repeat: Infinity,
    },
  },
};
export const nftFloatItemVariants: Variants = {
  initial: {
    left: "50%",
    translateX: "-50%",
  },
  idle: { ...floatItemIdleAnimation() },
  sacrifice: {
    y: -300,
    opacity: 0,
    transition: {
      duration: 3,
    },
  },
};
