import React from "react";
import NftCardHeaderImg from "./NftCardHeaderImg";

export type NftCardHeaderProps = {
  imgURL: string;
};

export const NftCardHeader: React.FC<NftCardHeaderProps> = ({ imgURL }) => (
  <div className="nft-card-header round-corners-wide">
    <NftCardHeaderImg imgURL={imgURL} />
  </div>
);
