import React from 'react';
import BackButton from '../../../../components/back-button/BackButton';
import EVGenericButton from '../../../../components/ev-generic-components/EVGenericButton';
import EVGenericText from '../../../../components/ev-generic-components/EVGenericText';
import ForegroundStageId from '../../../../constants/ForegroundStageId';
import { useAppDispatch, useAppSelector } from '../../../../state/hooks';
import { disconnectWallet } from '../../../../state/wallet-info-state';
import { resetBribe } from '../../../bribe/state';
import { setCurrentPlayedVideo, setForegroundStageId } from '../../../main-design-system/state';
import { ctaOneText, ctaTwoText } from '../../constants/selectNftTexts';
import { clearSelectedNft, selectNftData } from '../../state';
import './SelectNftFooter.scss';

const SelectNftFooter: React.FC = () => {
  const dispatch = useAppDispatch();

  const selectedNftName = useAppSelector(selectNftData('name'));

  const onBackClick = () => {
    dispatch(clearSelectedNft());
    dispatch(resetBribe());
    dispatch(disconnectWallet());
    dispatch(setForegroundStageId(ForegroundStageId.NULL));
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'DoorClosedBlueIdle',
        onVideoEnded: undefined,
      })
    );
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  const onNextClick = async () => {
    dispatch(setForegroundStageId(ForegroundStageId.LOADING));
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'HallwayToTemple',
        onVideoEnded: () => {
          dispatch(
            setCurrentPlayedVideo({
              videoName: 'TempleIdle',
              onVideoEnded: undefined,
            })
          );
          dispatch(setForegroundStageId(ForegroundStageId.SHRINE));
        },
      })
    );
  };

  return (
    <div className='select-nft-footer-container flex-center l-mt'>
      <div className='select-nft-footer-inner-container w-all'>
        <div className='cta2-grid-item flex row-reverse align-center justify-start'>
          <EVGenericText text={ctaTwoText} onClick={onBackClick} extraClassNames={'m-mr clickable-text'} />
        </div>
        <div className='cta1-grid-item'>
          <EVGenericButton text={ctaOneText} extraClassNames={'w-all'} disabled={!selectedNftName} cb={onNextClick} />
        </div>
        <div className='empty' />
      </div>
    </div>
  );
};
export default SelectNftFooter;
