import OutputI from "../../../../interfaces/Output";

const ethRgx = /^https:\/\/opensea\.io\/assets\/ethereum\/(0x)[a-zA-HJ-NP-Z0-9]{40}\/[0-9]+$/i;
const solRgx = /^https:\/\/opensea\.io\/assets\/solana\/[a-zA-HJ-NP-Z0-9]{44}$/i;
const polyRgx = /^https:\/\/opensea\.io\/assets\/matic\/(0x)[a-zA-HJ-NP-Z0-9]{40}\/[0-9]+$/i;

const validateUrlInput = (urlInput: string): OutputI<{}> => {
    try {
        if (typeof urlInput !== 'string')
            throw new Error( '\nOpensea url is not of type string!');
        const isEth = ethRgx.test(urlInput);
        const isSol = solRgx.test(urlInput);
        const isPoly = polyRgx.test(urlInput);
            if(!isEth && !isSol && !isPoly) throw new Error( '\nOpensea url does not comply with opensea url regex rules!');
        return { err: false };
    } catch (error) {
        return { err: true, msg: `\nvalidateUrlInput.ts::validateUrlInput():: Url is invalid! | Input: ${urlInput} | \n${error}` };
    }
};
 export default validateUrlInput;