import React from 'react';
import BackButton from '../../components/back-button/BackButton';
import ForegroundStageId from '../../constants/ForegroundStageId';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import { setForegroundStageId } from '../main-design-system/state';
import { OpenSeaNftValidatorStageId } from './constants/OpenSeaNftValidatorStageId';
import OpenSeaNftValidatorLeft from './OpenSeaNftValidatorLeft';
import OpenSeaNftValidatorRight from './OpenSeaNftValidatorRight';
import { selectOpenSeaNftValidator } from './state';

const OpenSeaNftValidator: React.FC = () => {
  const stageId = useAppSelector(selectOpenSeaNftValidator('stageId')) as OpenSeaNftValidatorStageId;

  const dispatch = useAppDispatch();

  const onBackButtonClick = async () => {
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  return (
    <div
      className={`opensea-nft-validator-container w-all flex align-end space-between ${
        stageId !== OpenSeaNftValidatorStageId.FORM && 'row-reverse'
      } `}
    >
      <OpenSeaNftValidatorRight />
      {stageId === OpenSeaNftValidatorStageId.FORM && <BackButton onBackClick={onBackButtonClick} />}
      {stageId !== OpenSeaNftValidatorStageId.FORM && <OpenSeaNftValidatorLeft />}
    </div>
  );
};

export default OpenSeaNftValidator;
