import OutputI from "../../../interfaces/Output";
import OpenseaNftValidatorResult from "./interfaces/OpenseaNftValidatorResult";
import validateUrlInput from "./utils/validateUrlInput";
import axios from "axios";
import mockRequest from "./utils/mockResponse";

/**
 * @KASH
 * @TODO - Please make sure endpoint is correct and production code is uncommented.
 * @param urlInput - The opensea url pointing to the nft being checked.
 * @returns 
 */
export const validateOpenseaNFT = async (urlInput :string): Promise<OutputI<OpenseaNftValidatorResult>> => {
    try {
        const isUrlValid = validateUrlInput(urlInput);
        if(isUrlValid.err) throw isUrlValid.msg;
        /**
         * @dev For production env, uncomment this code
         * PRODUCTION CODE START
         */
         const requestUrl = `/api/v1/public/nft/opensea-nft-check?openseaURL=${urlInput}`;
         const res = await axios.get(process.env.REACT_APP_BASE_URL_DEV + requestUrl);
        /** PRODUCTION CODE END */
        /**
         * @dev For development env, uncomment this code
         * DEVELOPMENT CODE START
         */
        // const res = await mockRequest(true);
        /** DEVELOPMENT CODE END */
        if(res.status !== 200) throw new Error(res.statusText);
        return { err: false, output: res.data as OpenseaNftValidatorResult };
    } catch (error) {
        return { err: true, msg: `\nopensea-validator::validateOpenseaUrl()::Failed to validate Opensea NFT | Input: ${urlInput} | \n${error}` };
    }
}

