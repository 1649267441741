import React from "react";
import "./WelcomeSectionHowItWorks.scss";
import WelcomeSectionHowItWorksContent from "./WelcomeSectionHowItWorksContent";
import WelcomeSectionHowItWorksHeader from "./WelcomeSectionHowItWorksHeader";

const WelcomeSectionHowItWorks: React.FC = () => {
  return (
    <div className="welcome-section-how-it-works-container bg-grad-ltr m-pad flex column align-center space-between round-corners-narrow">
      <WelcomeSectionHowItWorksHeader />
      <WelcomeSectionHowItWorksContent />
    </div>
  );
};

export default WelcomeSectionHowItWorks;
