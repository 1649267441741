import React from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import OpenseaNftValidatorResult from "../../api/interfaces/OpenseaNftValidatorResult";
import { useAppSelector } from "../../../../state/hooks";
import { selectOpenSeaNftValidator } from "../../state";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import { trigger } from "../../../../services/events";
import ReactGA from "react-ga";
import {
  resultLeftValidTitle,
  resultLeftInValidTitle,
  resultLeftValidSubTitle,
  resultLeftInValidSubTitle,
} from "../../constants/OpenSeaNftValidatorTexts";

const OpenSeaNftValidatorResultLeft: React.FC = () => {
  const { valid } = useAppSelector(
    selectOpenSeaNftValidator("validationResult")
  ) as OpenseaNftValidatorResult;

  const title = valid ? resultLeftValidTitle : resultLeftInValidTitle;
  const subtitle = valid ? resultLeftValidSubTitle : resultLeftInValidSubTitle;

  const toggleFAQ = () => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: "click-on-faq-toggle",
        category: "button",
      });
    }
    trigger("FAQ:toggle");
  };

  return (
    <div className="opensea-nft-validator-result-left-container flex column align-start">
      <EVGenericText
        text={title}
        size={"large"}
        type={"h2"}
        weight={"bold"}
        extraClassNames={"s-mb"}
      />
      <EVGenericText text={subtitle} type={"h3"} extraClassNames={"m-mb"} />
      <EVGenericButton
        text="FAQ"
        isDark={true}
        cb={toggleFAQ}
        customCSS={{
          width: "80%",
        }}
      />
    </div>
  );
};

export default OpenSeaNftValidatorResultLeft;
