import React, { useEffect, useRef } from "react";
import { useAppSelector } from "../../../state/hooks";
import AudioPlayer from "../AudioPlayer";
import { BackgroundAudio } from "../../../assets/audio";
import { selectGeneralInfo } from "../../../state/general-info-state";

const BackgroundAudioPlayer: React.FC = () => {
  const audioMuted = useAppSelector(selectGeneralInfo("audioMuted")) as boolean;
  const audioElem = useRef<HTMLAudioElement | null>(null);

  // This plays the audio track if its the audio is not currently playing.
  useEffect(() => {
    if (!audioMuted && audioElem.current?.paused) audioElem.current?.play();
  }, [audioMuted]);

  // This is how we will set the audio track's volume.
  useEffect(() => {
    if (audioElem) audioElem.current!.volume = 0.0975;
  }, []);

  return (
    <AudioPlayer
      ref={audioElem}
      shouldPlayOnMount={false}
      audioSrc={BackgroundAudio}
      loop={true}
    />
  );
};

export default BackgroundAudioPlayer;
