import React, { useEffect } from "react";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { useAppDispatch } from "../../../../state/hooks";
import { setCurrentPlayedVideo } from "../../state";
import { titleTxt, subtitleTxt } from "./constants/mobileDisplayTexts";

const MobileDisplay: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorClosedRedIdle",
      })
    );
  }, [dispatch]);

  return (
    <main className="foreground-renderer-container flex column-reverse">
      <div className="mobile-text-container flex column m-mb align-center">
        <EVGenericText
          text={titleTxt}
          size={"custom"}
          weight={"bold"}
          extraClassNames={"s-mb"}
          customCSS={{
            fontSize: "3.5rem",
          }}
        />
        <EVGenericText
          text={subtitleTxt}
          size={"small"}
          customCSS={{
            textAlign: "center",
          }}
        />
      </div>
    </main>
  );
};

export default MobileDisplay;
