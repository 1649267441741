import React from "react";
import "./OpenSeaNftValidatorLeft.scss";
import OpenSeaNftValidatorConnectedLeft from "./components/connected/OpenSeaNftValidatorConnectedLeft";
import OpenSeaNftValidatorResultLeft from "./components/result/OpenSeaNftValidatorResultLeft";
import { OpenSeaNftValidatorStageId } from "./constants/OpenSeaNftValidatorStageId";
import { selectOpenSeaNftValidator } from "./state";
import { useAppSelector } from "../../state/hooks";
import NftCard from "../../components/nft-card/NftCard";
import OpenseaNftValidatorResult from "./api/interfaces/OpenseaNftValidatorResult";

const OpenSeaNftValidatorLeft: React.FC = () => {
  const stageId = useAppSelector(
    selectOpenSeaNftValidator("stageId")
  ) as OpenSeaNftValidatorStageId;

  const { imgURL, name } = useAppSelector(
    selectOpenSeaNftValidator("validationResult")
  ) as OpenseaNftValidatorResult;
  return (
    <div className="opensea-nft-validator-left-container round-corners-narrow l-pad bg-grad-ltr">
      {stageId !== OpenSeaNftValidatorStageId.FORM && (
        <NftCard imgURL={imgURL} name={name} />
      )}
      {stageId === OpenSeaNftValidatorStageId.RESULT && (
        <OpenSeaNftValidatorResultLeft />
      )}
      {stageId === OpenSeaNftValidatorStageId.CONNECTED && (
        <OpenSeaNftValidatorConnectedLeft />
      )}
    </div>
  );
};
export default OpenSeaNftValidatorLeft;
