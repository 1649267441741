import CustomEventNames from "./CustomEventNames";

const on = (eventType: CustomEventNames, listener: EventListenerOrEventListenerObject) => {
  document.addEventListener(eventType, listener);
}

function off(eventType: CustomEventNames, listener: EventListenerOrEventListenerObject) {
  document.removeEventListener(eventType, listener);
}

function trigger<T>(eventType: CustomEventNames, data?: T) {
  const event = new CustomEvent(eventType, { detail: data });
  document.dispatchEvent(event);
}

export { on, off, trigger };