import React from 'react';
import EVGenericButton from '../../../../components/ev-generic-components/EVGenericButton';
import EVGenericConnectWalletButton from '../../../../components/ev-generic-components/EVGenericConnectWalletButton';
import ForegroundStageId from '../../../../constants/ForegroundStageId';
import WalletStatusId from '../../../../constants/WalletStatusId';
import { useAppDispatch } from '../../../../state/hooks';
import connectWalletAsyncAction from '../../../../state/wallet-info-state/actions/connectWalletAsyncAction';
import waitForSeconds from '../../../../utils/waitForSeconds';
import { setCurrentPlayedVideo, setForegroundStageId } from '../../../main-design-system/state';
import { faqButton, termsOfUseButton } from './constants/WelcomeSectionConnectWalletTexts';
import ReactGA from 'react-ga';
import { trigger } from '../../../../services/events';
import openTermsOfUseNewTab from '../../../../utils/openTermsOfUseNewTab';
import EVGenericText from '../../../../components/ev-generic-components/EVGenericText';

const WelcomeSectionConnectWallet: React.FC = () => {
  const dispatch = useAppDispatch();

  const moveToOpenseaNFTValidator = () => {
    dispatch(setForegroundStageId(ForegroundStageId.OPENSEA_NFT_VALIDATOR));
  };

  const backToConnectWallet = () => {
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'DoorClosedBlueIdle',
      })
    );
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  const showInvalidWallet = () =>
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'DoorIdleToClosedRed',
        // Play following idle video.
        onVideoEnded: () => {
          dispatch(
            setCurrentPlayedVideo({
              videoName: 'DoorClosedRedIdle',
              onVideoEnded: undefined,
            })
          );
          // Show UI
          dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
        },
      })
    );

  const onConnectWalletClick = async () => {
    try {
      // Hide UI
      dispatch(setForegroundStageId(ForegroundStageId.LOADING));
      // Play loading animation in background.
      dispatch(
        setCurrentPlayedVideo({
          videoName: 'DoorClosedYellowIdle',
          onVideoEnded: undefined,
        })
      );
      // Call ga event
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.event({
          action: 'click-on-check',
          category: 'button',
        });
      }
      // Get opensea url check from api.
      const res = await dispatch(connectWalletAsyncAction()).unwrap();

      await waitForSeconds(Math.floor(Math.random() * 3 + 1));
      if (res.err) {
        backToConnectWallet();
        throw new Error(res.msg);
      }
      if (!res.output) {
        backToConnectWallet();
        throw new Error('output is undefined');
      }
      // Play transition video.
      if (res.output.walletStatusId === WalletStatusId.MINT) {
        backToConnectWallet();
      } else {
        showInvalidWallet();
      }
    } catch (error) {
      console.error(
        `OpenSeaNftValidatorForm.tsx::OpenSeaNftValidatorForm():: Failed to complete onSubmit() | \n${error}`
      );
    }
  };

  const toggleFAQ = () => {
    if (process.env.REACT_APP_GA_ID) {
      ReactGA.event({
        action: 'click-on-faq-toggle',
        category: 'button',
      });
    }
    trigger('FAQ:toggle');
  };

  return (
    <div className='welcome-section-connect-wallet flex-center column m-pad bg-grad-ltr round-corners-narrow'>
      <EVGenericConnectWalletButton onConnectWalletClick={onConnectWalletClick} />
      <EVGenericButton text={faqButton} isDark={true} cb={toggleFAQ} extraClassNames={'m-mt m-mb'} />
      <EVGenericText text={termsOfUseButton} onClick={() => openTermsOfUseNewTab()} extraClassNames={'clickable-text'} />
    </div>
  );
};

export default WelcomeSectionConnectWallet;
