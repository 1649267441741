import OutputI from '../../interfaces/Output';
import DoorOpenIdle from './EV_DoorOpenedLoop.mp4';
import DoorClosedRedIdle from './EV_DoorDenialLoop.mp4';
import DoorClosedYellowIdle from './EV_DoorLoadingLoop.mp4';
import DoorClosedBlueIdle from './EV_DoorInactionLoop.mp4';
import DoorIdleToOpened from './EV_DoorOpen.mp4';
import DoorIdleToClosedRed from './EV_DoorDenial.mp4';
import TempleIdle from './EV_TempleIdle.mp4';
import TempleSacrifice from './EV_TempleSacrifice.mp4';
import HallwayToTemple from './EVHallwayFlyDown.mp4';
import HallwayIdle from './Hallway_Idle.mp4';

export type BGVideoNames = 'DoorClosedBlueIdle' | 'DoorClosedRedIdle'| 'DoorIdleToClosedRed'| 'DoorIdleToOpened'| 'DoorOpenIdle' | 'DoorClosedYellowIdle' | 'TempleIdle' |'TempleSacrifice' | 'HallwayToTemple' | 'HallwayIdle' ;

const getVideoFileByName = (name: BGVideoNames): OutputI<string> => {
    try {
        if(typeof name !== 'string') throw new Error('input is not of type string!')
        let output;
        switch (name) {
            case 'DoorClosedBlueIdle':
            output = DoorClosedBlueIdle
            break;
            case 'DoorClosedRedIdle':
            output = DoorClosedRedIdle
            break;
            case 'DoorClosedYellowIdle':
            output = DoorClosedYellowIdle
            break;
            case 'DoorIdleToClosedRed':
            output = DoorIdleToClosedRed
            break;
            case 'DoorIdleToOpened':
            output = DoorIdleToOpened
            break;
            case 'DoorOpenIdle':
            output = DoorOpenIdle
            break;
            case 'TempleIdle':
            output = TempleIdle;
            break;
            case 'TempleSacrifice':
            output = TempleSacrifice;
            break;
            case 'HallwayToTemple':
            output = HallwayToTemple;
            break;
            case 'HallwayIdle':
            output = HallwayIdle;
            break;
            default:
                throw new Error('name is invalid!');
            }
        return{err: false, output}
    } catch (error) {
        return {err: true, msg: `videos/index.ts::getVideoFileByName():: Failed to get video file! | Input: ${name} | \n${error}`}
    }
}

export default getVideoFileByName