enum WalletStatusId {
  NULL = 0,
  MINT = 1,
  NO_QUALIFIED_NFTS = 2,
  EXCEEDED_AMOUNT = 3,
  NOT_WHITELISTED = 4, // User does not have a whitelist ev token.
  NOT_REGISTERED = 5,
  BLESSED_DONE=6,
}

export default WalletStatusId;
