import React from "react";
import EVGenericButton from "../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../components/ev-generic-components/EVGenericText";
import { useAppDispatch } from "../../../../../state/hooks";
import waitForSeconds from "../../../../../utils/waitForSeconds";
import {
    beforeEthMintText
} from "../../../constants/bribeTxts";
import { setTextBubbleIndex } from "../../../state";
import { TextBubbleIndex } from "../constants/TextBubbleIndex";
import { defaultPosition } from "../constants/textBubblesPositionCSS";
import { BaseTextBubble } from "./BaseTextBubble";

import ReactGA from 'react-ga';
import ForegroundStageId from "../../../../../constants/ForegroundStageId";
import WalletStatusId from "../../../../../constants/WalletStatusId";
import { setCurrentPlayedVideo } from "../../../../main-design-system/state";
import { setForegroundStageId } from "../../../../main-design-system/state";
import connectWalletAsyncAction from "../../../../../state/wallet-info-state/actions/connectWalletAsyncAction";

const BeforeBribeTextBubble: React.FC = () => {
  const dispatch = useAppDispatch();

  const onBeforeBribeClick = async () => {
    dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
    await waitForSeconds(0.2);
    dispatch(setTextBubbleIndex(TextBubbleIndex.CURRENT_MINT));
  };

  const backToConnectWallet = () => {
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'DoorClosedBlueIdle',
      })
    );
    dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
  };

  const showInvalidWallet = () =>
    dispatch(
      setCurrentPlayedVideo({
        videoName: 'DoorIdleToClosedRed',
        // Play following idle video.
        onVideoEnded: () => {
          dispatch(
            setCurrentPlayedVideo({
              videoName: 'DoorClosedRedIdle',
              onVideoEnded: undefined,
            })
          );
          // Show UI
          dispatch(setForegroundStageId(ForegroundStageId.WELCOME_SECTION));
        },
      })
    );

  const onConnectWalletClick = async () => {
    try {
      // Hide UI
      //dispatch(setForegroundStageId(ForegroundStageId.LOADING));
      //// Play loading animation in background.
      //dispatch(
      //  setCurrentPlayedVideo({
      //    videoName: 'DoorClosedYellowIdle',
      //    onVideoEnded: undefined,
      //  })
      //);
      // Call ga event
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.event({
          action: 'click-on-check',
          category: 'button',
        });
      }
      // Get opensea url check from api.
      const res = await dispatch(connectWalletAsyncAction()).unwrap();
      // @ts-ignore
      res.output.walletStatusId = WalletStatusId.MINT
      //await waitForSeconds(Math.floor(Math.random() * 3 + 1));
      if (res.err) {
        backToConnectWallet();
        throw new Error(res.msg);
      }
      if (!res.output) {
        backToConnectWallet();
        throw new Error('output is undefined');
      }
      // Play transition video.
      //if (res.output.walletStatusId === WalletStatusId.MINT) {
      //  backToConnectWallet();
      //} else {
      //  showInvalidWallet();
      //}
      dispatch(setTextBubbleIndex(TextBubbleIndex.CURRENT_MINT));
    } catch (error) {
      console.error(
        `OpenSeaNftValidatorForm.tsx::OpenSeaNftValidatorForm():: Failed to complete onSubmit() | \n${error}`
      );
    }
  };

  return (
    <>
        <BaseTextBubble positionCSS={defaultPosition} direction={"left"}>           
            <div className="flex column justify-start align-center s-mb">
                <EVGenericText
                    text={beforeEthMintText}
                    extraClassNames={"m-line-h s-mb"}
                />
                <EVGenericButton              
                    text={`Connect wallet`}
                    cb={onConnectWalletClick}
                />
            </div>
        </BaseTextBubble>
        
    </>
  );
};

export default BeforeBribeTextBubble;
