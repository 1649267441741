import React from "react";
import { INftDisplay } from "../../interfaces/NftData";
import "./NftCard.scss";
import { NftCardFooter } from "./NftCardFooter";
import { NftCardHeader } from "./NftCardHeader";

export interface NftCardProps extends INftDisplay {
  onClick?: () => void;
  extraClassNames?: string;
}

const NftCard: React.FC<NftCardProps> = ({
  imgURL,
  name,
  onClick,
  extraClassNames,
}) => {
  const onNftCardClick = () => {
    onClick
      ? onClick()
      : console.log(
          `nft-card-container clicked! | title: ${name} | imgURL: ${imgURL}`
        );
  };

  return (
    <div
      className="nft-card-container round-corners-wide flex-center column s-mt extraClassNames"
      onClick={onNftCardClick}
    >
      <NftCardHeader imgURL={imgURL} />
      {name && <NftCardFooter title={name} />}
    </div>
  );
};

export default NftCard;
