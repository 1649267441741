import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import { EthIcon } from "../../../../assets/images";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import getAmountNFTsRemaining from "../../../../services/ethers/get-amount-nfts-remaining";
import { useAppSelector } from "../../../../state/hooks";
import { selectWalletInfo } from "../../../../state/wallet-info-state";
import { currentHelperBubbleText } from "../../constants/bribeTxts";
import "./BribeMintCard.scss";

type BribeMintCardProps = HeaderProps & EthAmountProps & FooterProps;
const BribeMintCard: React.FC<BribeMintCardProps> = ({
  ctaFn,
  ctaText,
  disabled,
  img,
  ethAmount,
  userBalance
}) => (
  <div className="mint-card-container flex-center column">
    <Header img={img} />
    <EthAmount ethAmount={ethAmount} />   
    <Footer ctaText={ctaText} ctaFn={ctaFn} disabled={disabled} userBalance={userBalance} />
  </div>
);

type HeaderProps = {
  img: string;
};
const Header: React.FC<HeaderProps> = ({ img }) => (
  <img className="s-mb" src={img} alt="mint" />
);

type FooterProps = {
  ctaText: string;
  ctaFn: Function;
  disabled: boolean;
  userBalance: number
};
const Footer: React.FC<FooterProps> = ({ ctaFn, ctaText, disabled, userBalance }) => {
  const [clicked, setClicked] = useState(false);
  const [numOfNftsRemaining, setNumOfNftsRemaining] = useState(5000);
  const provider = useAppSelector(
    selectWalletInfo("provider")
  ) as ethers.providers.Web3Provider;

  const onButtonClick = () => {
    if (clicked) return;
    setClicked(true);
    ctaFn();
  };

  const getNumOfNftsRemaining = async () => {
    try {
      const res = await getAmountNFTsRemaining(provider);
      if (res.err) throw new Error(res.msg);
      if (res.output === undefined) throw new Error("output is undefined!");
      setNumOfNftsRemaining(res.output);
    } catch (error) {
      console.error(
        `bribe/componentes/mint-card/BribeMintCard.tsx::getNumOfNftsRemaining:: Failed to get num of nfts remaining! | \n${error}`
      );
    }
  };

  useEffect(() => {
    getNumOfNftsRemaining();
  }, []);

  return (
    <div className="flex-center column cta-wrapper">
      <EVGenericText
        text={numOfNftsRemaining + " Remaining"}
        size={"x-small"}
        extraClassNames={"xs-mb"}
      />      
      {
        userBalance <= parseFloat(process.env.REACT_APP_ETH_MINT_AMOUNT || "0") ?
            <EVGenericText text={"You don't have enough eth"} size={"x-small"} extraClassNames="m-mb" customCSS={{ color: "#e8c37a69"}} />
        :
            <EVGenericButton
                text={ctaText}
                cb={onButtonClick}
                disabled={disabled || clicked}
                extraClassNames={"s-mb"}
            />
      }
      <EVGenericText text={currentHelperBubbleText} size={"x-small"} />     
    </div>
  );
};

type EthAmountProps = {
  ethAmount: string;
};
const EthAmount: React.FC<EthAmountProps> = ({ ethAmount }) => (
  <div className="bribe-mint-card-footer flex-center s-mb">    
    <img src={EthIcon} alt="eth" className="xs-mr" />
    <EVGenericText
      text={ethAmount}
      size={"custom"}
      customCSS={{ fontFamily: "Inter", fontSize: "2rem" }}
    />
  </div>
);

export default BribeMintCard;
