import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import INftData from '../../../interfaces/NftData';
import ReducerSliceNames from '../../../state/constants/ReducerSliceNames';
import { RootState } from '../../../state/store';

export const selectedNftDataInitState: INftData = {
  imgURL: "",
  swapInfo: {
    address: "",
    tokenId: -1,
    contractType: ""
  },
  name: ""
};


const selectedNftDataSlice = createSlice({
  name: ReducerSliceNames.SELECTED_NFT_DATA,
  initialState: selectedNftDataInitState,
  reducers: {
    setSelectedNft: (state, action :PayloadAction<INftData>)=>{
      state.imgURL = action.payload.imgURL
      state.name = action.payload.name
      state.swapInfo = action.payload.swapInfo
    },
    clearSelectedNft: (state)=>{
      state.imgURL = ""
      state.name = ""
      state.swapInfo = {
        address: "",
        tokenId: -1,
        contractType: ""
      }
    }
  },
});

export const { setSelectedNft, clearSelectedNft } = selectedNftDataSlice.actions;
export type SelectedNftDataSelectors = "imgURL" | "name" | "swapInfo"
export const selectNftData = (selector: SelectedNftDataSelectors)=>(state: RootState) => state.selectedNftData[selector];
export const getSelectedNftData = ()=> (state: RootState) => state.selectedNftData;
export default selectedNftDataSlice.reducer;
