import {
  approveTransfer,
  sacrificeStakeNft,
} from "../../../../../services/ethers/sacrifice-flow";
import { PopupContentEnum } from "../../content/components/ctas/contants/sacrificePopupContent";

export default new Map<PopupContentEnum, Function>([
  [PopupContentEnum.APPROVE_TRANSFER, approveTransfer],
  [PopupContentEnum.SACRIFICE, sacrificeStakeNft],
  [PopupContentEnum.SACRIFICE_STAKE, sacrificeStakeNft],
]);
