export const introOneBubbleText = `Psst...`;
export const introTwoBubbleText = `Don’t want to sacrifice an NFT?`;
export const introCtaBubbleText = `Bribe the guard`;
export const beforeFirstBubbleText = `Yo, Smartass... I can get you a\nViking without sacrificing shit...`;
export const beforeSecondBubbleText = `But it'll cost you.`;
export const beforeCtaBubbleText = `I'll take it!`;
export const currentCtaBubbleText = `Mint&Stake 1 Viking`;
export const currentHelperBubbleText = `With Mint&Stake you join the war,\nimmediatly begin earning $GOLD\ntokens and become a true Viking!`;
export const afterBubbleText = `Great doing business with ya...\nNow, head onto the Panel and watch\nyour $GOLD begin to pile up.`;
export const afterCtaBubbleText = `Go to Panel`;

export const beforeEthMintText = "The old gods are not longer\naccepting sacrifices, but I can\nget you a viking instead..."