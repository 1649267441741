import OutputI from "../../../interfaces/Output";
import { ethers } from "ethers";
import INftData from "../../../interfaces/NftData";
import IGetWalletInfoResponse from "../../../state/wallet-info-state/interface/GetWalletInfoResponse";

import getUserAllNfts from "../get-user-all-nfts";
import axios from "axios";

/**
 * @param walletAddress - The user's connected wallet's address as string.
 * @returns The qualified NFTs of the user
 */
const getUserQualifiedNfts = async (provider: ethers.providers.Web3Provider, walletAddress: string): Promise<OutputI<IGetWalletInfoResponse>> =>{
    try {
        let output: IGetWalletInfoResponse | undefined = undefined;

        let tries = 0;
        while(tries < 4) {
            tries++
            try {
                let res = await axios.get(process.env.REACT_APP_WALLET_CHECKER_URL || "", { params: { walletAddress } });
                output = res.data;
                break;
            } catch (e) {}
        }
       
        if (output !== undefined && output?.qualifiedNfts) {
            return { err: false, output}
        }

        tries = 0;
        while(tries < 4) {
            tries++
            try {
                let userAllNftsOutput = await getUserAllNfts(provider, walletAddress);
                if (!userAllNftsOutput.err && userAllNftsOutput.output) {
                    let userAllNfts = userAllNftsOutput.output
                    let userQualifiedNftsRes = await axios.post(process.env.REACT_APP_WALLET_CHECKER_URL || "", {
                        userAllNfts
                    });
                    let userQualifiedNfts = userQualifiedNftsRes.data;
                    output = userQualifiedNfts
                    break;
                }
            } catch (e) {}
        }

        if (output !== undefined && output.qualifiedNfts) {
            return { err: false, output}
        } else
            return { err: true, msg: `get-user-qualified-nfts/index.ts::getUserQualifiedNfts():: Did not get user qualified Nfts!` }
    } catch (error) {
        return {err: true, msg: `get-user-qualified-nfts/index.ts::getUserQualifiedNfts():: Failed to get user qualified Nfts! || \n${error}`};
    }
}

export default getUserQualifiedNfts;