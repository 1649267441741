import React from "react";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import {
  beforeMintCtaOneText,
  ctaSeparatorText,
  beforeMintCtaTwoText,
} from "../../../../constants/sacrificeSectionText";
import { MintState } from "../../../../SacrificeSection";
import { SacrificePopupStateProps } from "../../../sacrifice-popup/SacrificePopup";
import { MintStateProp } from "../../SacrificeSectionContent";

export type PopupStateProps = SacrificePopupStateProps & {
  isOpen: boolean;
};

const SacrificeSectionContentCTAsBeforeSacrifice: React.FC<MintStateProp> = ({
  mintState,
  setMintState,
}) => {
  const onSacrificeNftClick = () => setMintState(MintState.SACRIFICE_POPUP);
  const onSacrificeStakeNftClick = () =>
    setMintState(MintState.SACRIFICE_STAKE_POPUP);

  return (
    <>
      <EVGenericButton
        text={beforeMintCtaOneText}
        cb={onSacrificeStakeNftClick}
        disabled={mintState !== MintState.BEFORE}
        customCSS={{ width: "73%", maxWidth: "29rem" }}
      />
      <EVGenericText text={ctaSeparatorText} />
      <EVGenericText
        text={beforeMintCtaTwoText}
        onClick={() => {
          if (mintState === MintState.BEFORE) onSacrificeNftClick();
        }}
        extraClassNames={"clickable-text"}
      />
    </>
  );
};

export default SacrificeSectionContentCTAsBeforeSacrifice;
