import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import generalInfoReducer from './general-info-state';
import walletInfoReducer from './wallet-info-state';
import openSeaNftValidatorReducer from '../features/opensea-nft-validator/state';
import mainLayoutSystemReducer from '../features/main-design-system/state';
import selectedNftDataReducer from '../features/select-nft/state';
import bribeReducer from '../features/bribe/state';

export const store = configureStore({
  reducer: {
    openSeaNftValidator: openSeaNftValidatorReducer,
    generalInfo: generalInfoReducer,
    walletInfo: walletInfoReducer,
    mainLayoutSystem: mainLayoutSystemReducer,
    selectedNftData: selectedNftDataReducer,
    bribe: bribeReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
