import { useState, useEffect } from "react";

export type useCountdownOutputValues = [hours: number, minutes: number, seconds: number];

/**
 * @dev A hook which accepts a milliseconds representation of the target date for the countdown
 * and returns A tuple with the amount of days, hours, minutes and seconds values.
 * @param {number} targetDate - Milliseconds representation of the target date.
 * @return {useCountdownOutputValues} [days, hours, minutes, seconds] - A tuple with the amount of days, hours, minutes and seconds values.
 */
const useContdown = (targetDate: number): useCountdownOutputValues => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(
    countDownDate - new Date().getTime()
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown);
}

const getReturnValues = (countDown: number):useCountdownOutputValues => {

  // calculate time left
  const hours = Math.floor(countDown / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
}

export default useContdown;