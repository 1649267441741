import React, { useEffect, useState } from "react";
import "./OpenSeaNftValidatorForm.scss";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import StateStatusNames from "../../../../interfaces/StateStatusNames";
import { useAppDispatch, useAppSelector } from "../../../../state/hooks";
import { selectOpenSeaNftValidator, setStageId } from "../../state";
import { sendOpenseaNftValidationAsyncAction } from "../../state/actions/sendOpenseaNftValidationAsyncAction";
import OpenSeaNftValidatorInput from "./OpenSeaNftValidatorInput";
import validateUrlInput from "../../api/utils/validateUrlInput";
import {
  setCurrentPlayedVideo,
  setForegroundStageId,
  setOnForegroundFadeAnimationEnd,
} from "../../../main-design-system/state";
import ForegroundStageId from "../../../../constants/ForegroundStageId";
import { OpenSeaNftValidatorStageId } from "../../constants/OpenSeaNftValidatorStageId";
import waitForSeconds from "../../../../utils/waitForSeconds";
import ReactGA from "react-ga";
import {
  formCheckButtonTxt,
  formTitleTxt,
} from "../../constants/OpenSeaNftValidatorTexts";

const OpenSeaNftValidatorForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const inputValue = useAppSelector(
    selectOpenSeaNftValidator("inputValue")
  ) as string;
  const requestStatus = useAppSelector(
    selectOpenSeaNftValidator("status")
  ) as StateStatusNames;

  const onSubmit = async () => {
    try {
      // Add on fade *out* animation end event. (Will be called )
      dispatch(
        setOnForegroundFadeAnimationEnd(() => {
          dispatch(setStageId(OpenSeaNftValidatorStageId.RESULT));
        })
      );
      // Play loading animation in background.
      dispatch(
        setCurrentPlayedVideo({
          videoName: "DoorClosedYellowIdle",
          onVideoEnded: undefined,
        })
      );
      // Hide UI
      dispatch(setForegroundStageId(ForegroundStageId.LOADING));
      // Call ga event
      if (process.env.REACT_APP_GA_ID) {
        ReactGA.event({
          action: "click-on-check",
          category: "button",
        });
      }
      await waitForSeconds(Math.floor(Math.random() * 3 + 1));
      // Get opensea url check from api.
      const res = await dispatch(
        sendOpenseaNftValidationAsyncAction(inputValue)
      ).unwrap();
      // Play transition video.
      dispatch(
        setCurrentPlayedVideo({
          videoName: res.output?.valid
            ? "DoorIdleToOpened"
            : "DoorIdleToClosedRed",
          // Play following idle video.
          onVideoEnded: () => {
            dispatch(
              setCurrentPlayedVideo({
                videoName: res.output?.valid
                  ? "DoorOpenIdle"
                  : "DoorClosedRedIdle",
                onVideoEnded: undefined,
              })
            );
            // Show UI
            dispatch(
              setForegroundStageId(ForegroundStageId.OPENSEA_NFT_VALIDATOR)
            );
          },
        })
      );
    } catch (error) {
      console.error(
        `OpenSeaNftValidatorForm.tsx::OpenSeaNftValidatorForm():: Failed to complete onSubmit() | \n${error}`
      );
    }
  };

  const [urlValid, setUrlValid] = useState(true);
  useEffect(() => {
    if (inputValue.length === 0) {
      setUrlValid(true);
      return;
    }
    const res = validateUrlInput(inputValue);
    setUrlValid(!res.err);
  }, [inputValue]);

  return (
    <div className="opensea-nft-validator-form flex column align-start">
      <EVGenericText
        text={formTitleTxt}
        size={"large"}
        extraClassNames={"s-mb"}
      />
      <OpenSeaNftValidatorInput urlValid={urlValid} />
      <EVGenericButton
        text={formCheckButtonTxt}
        cb={onSubmit}
        disabled={
          requestStatus !== "idle" || !urlValid || inputValue.length === 0
        }
        customCSS={{
          width: "50%",
        }}
      />
    </div>
  );
};

export default OpenSeaNftValidatorForm;
