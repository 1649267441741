import React, { useEffect, useState } from "react";
import "./FAQ.scss";
import { faqs } from "../../../../constants/faqs";
import { off, on } from "../../../../services/events";
import { FAQParagraph, FAQTextProps } from "./FAQParagraph";
import { Xicon } from "../../../../assets/images";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import { AnimatePresence, motion } from "framer-motion";
import { faqTitleTxt } from "../../constants/OpenSeaNftValidatorTexts";

const FAQ: React.FC = () => {
  const [state, setState] = useState(false);

  useEffect(() => {
    on("FAQ:toggle", () => setState(!state));
    return () => off("FAQ:toggle", () => setState(!state));
  }, [state]);

  return (
    <AnimatePresence>
      {state && (
        <motion.article
          className="faq-container flex column round-corners-narrow"
          initial={{
            x: 700,
          }}
          animate={{
            x: 0,
            transition: { duration: 0.75 },
          }}
          exit={{
            x: 700,
            transition: { duration: 0.75 },
          }}
        >
          <div className="faq-header flex space-between align-center s-mb">
            <EVGenericText
              text={faqTitleTxt}
              type={"h2"}
              weight={"bold"}
              extraClassNames={"s-mb"}
              size={"medium"}
            />
            <img
              src={Xicon}
              id="close-faq"
              alt="close-faq"
              onClick={() => setState(!state)}
            />
          </div>
          {faqs &&
            faqs.map(({ question, answer }: FAQTextProps) => (
              <FAQParagraph question={question} answer={answer} />
            ))}
        </motion.article>
      )}
    </AnimatePresence>
  );
};
export default FAQ;
