import React from "react";
import EVGenericButton from "../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../components/ev-generic-components/EVGenericText";
import ForegroundStageId from "../../../../constants/ForegroundStageId";
import { useAppDispatch } from "../../../../state/hooks";
import { GuardVariantNames } from "../../../bribe/components/gaurd-entity/animations/variants";
import { TextBubbleIndex } from "../../../bribe/components/text-bubble/constants/TextBubbleIndex";
import { setTextBubbleIndex } from "../../../bribe/state";
import setGaurdAnimationVariant from "../../../bribe/state/actions";
import {
  setCurrentPlayedVideo,
  setForegroundStageId,
} from "../../../main-design-system/state";
import { ctaOneText, titleText } from "./constants/EnterTempleTexts";
import "./WelcomeSectionEnterTemple.scss";

const WelcomeSectionEnterTemple: React.FC = () => {
  return (
    <div className="welcome-section-enter-temple bg-grad-ltr m-pad flex column justify-center align-center round-corners-narrow">
      <RenderHeader />
      <RenderFooter />
    </div>
  );
};

const RenderHeader: React.FC = () => (
  <>
    <EVGenericText
      text={titleText}
      size={"large"}
      type={"h2"}
      extraClassNames={"m-mb"}
    />
  </>
);

const RenderFooter: React.FC = () => {
  const dispatch = useAppDispatch();

  const hideGuard = async () => {
    dispatch(setTextBubbleIndex(TextBubbleIndex.NULL));
    await dispatch(
      setGaurdAnimationVariant(GuardVariantNames.SNEAK_HIDDEN)
    ).unwrap();
  };

  const onEnterTempleClick = async () => {
    // Hide guard.
    await hideGuard();
    // Hide UI.
    dispatch(setForegroundStageId(ForegroundStageId.LOADING));
    // Play loading animation in background.
    dispatch(
      setCurrentPlayedVideo({
        videoName: "DoorIdleToOpened",
        onVideoEnded: () => {
          // Play idle animation in background.
          dispatch(
            setCurrentPlayedVideo({
              videoName: "HallwayIdle",
              onVideoEnded: undefined,
            })
          );
          dispatch(setForegroundStageId(ForegroundStageId.HALLWAY));
        },
      })
    );
  };

  return (
    <>
      <EVGenericButton
        text={ctaOneText}
        customCSS={{ width: "80%" }}
        cb={onEnterTempleClick}
      />
    </>
  );
};

export default WelcomeSectionEnterTemple;
