import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import EVGenericButton from "../../../../../../components/ev-generic-components/EVGenericButton";
import EVGenericText from "../../../../../../components/ev-generic-components/EVGenericText";
import checkCanMintAgainEthers from "../../../../../../services/ethers/check-can-mint-again";
import { useAppSelector } from "../../../../../../state/hooks";
import { selectWalletInfo } from "../../../../../../state/wallet-info-state";
import openPanelInNewTab from "../../../../../../utils/openPanelInNewTab";
import {
  ctaSeparatorText,
  afterMintCtaOneText,
  afterMintCtaTwoText,
} from "../../../../constants/sacrificeSectionText";

const SacrificeSectionContentCTAsAfterSacrifice: React.FC = () => {
  const [canMintAgain, setCanMintAgain] = useState(false);
  const provider = useAppSelector(
    selectWalletInfo("provider")
  ) as ethers.providers.Web3Provider;

  useEffect(() => {
    (async () => {
      const res = await checkCanMintAgainEthers(provider);
      if (res.err) return;
      setCanMintAgain(!!res.output);
    })();
  }, []);

  return (
    <>
      <EVGenericButton
        text={afterMintCtaOneText}
        customCSS={{ width: "73%", maxWidth: "29rem" }}
        cb={() => openPanelInNewTab()}
      />
      {canMintAgain && (
        <>
          <EVGenericText text={ctaSeparatorText} />
          <EVGenericText
            text={afterMintCtaTwoText}
            onClick={() => window.location.reload()}
            extraClassNames={"clickable-text"}
          />
        </>
      )}
    </>
  );
};

export default SacrificeSectionContentCTAsAfterSacrifice;
